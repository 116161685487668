import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useApi } from '../../hooks/useApi';
import { AddItemToCart } from '../../api/CartApi';
import { LoadingButton } from '@mui/lab';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';

export const CartButton = ({
  fullWidth = true,
  color = 'primary',
  variant = 'outlined',
  size = 'small',
  disabled,
  sx = {},
  cartItem,
  setCartItem,
  smallItemButton = false,

  updateStockHandler,
}) => {
  const [openAddToCartDrawer, setOpenAddToCartDrawer] = useState(false);
  const [selectAId, setSelectAId] = useState({});
  const AddItemToCartApi = useApi(AddItemToCart);
  const [cookie] = useCookies('jwt');
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectAId(
      cartItem?.aIDs?.length
        ? cartItem?.aIDs?.sort((a, b) => (a.stock >= b.stock ? -1 : 1))[0]
        : {},
    );
  }, [cartItem]);
  const AddToCartHandler = async () => {
    const result = await AddItemToCartApi.sendRequest({
      jwt: cookie?.jwt,
      cartItem: {
        ...cartItem,
        variationId: selectAId.variationId,
        aID: selectAId.aID,
        color: selectAId.color,
        size: selectAId.size,
      },
    });

    if (result.id) {
      dispatch(setAlert({ status: 200, text: 'Товар добавлен в корзину' }));
      updateStockHandler();
    } else {
      dispatch(
        setAlert({
          status: 500,
          text: 'При добавлении товара в корзину произошла ошибка',
        }),
      );
    }
  };

  return (
    <>
      <LoadingButton
        variant={variant}
        color={color}
        size={size}
        fullWidth={fullWidth}
        sx={sx}
        loading={AddItemToCartApi?.loading}
        disabled={disabled}
        startIcon={<ShoppingCartOutlinedIcon />}
        onClick={() => setOpenAddToCartDrawer(true)}
      >
        {cartItem?.aIDs?.length > 1 || +selectAId?.stock
          ? 'Купить'
          : smallItemButton
          ? 'Купить'
          : 'Нет в наличии'}
      </LoadingButton>
      <SwipeableDrawer
        anchor="bottom"
        open={openAddToCartDrawer}
        onClose={() => {
          updateStockHandler();
          setOpenAddToCartDrawer(false);
        }}
        onOpen={() => setOpenAddToCartDrawer(true)}
      >
        <Box sx={{ p: 4 }}>
          <Typography>
            {cartItem?.name} (
            {selectAId.color && selectAId.size
              ? selectAId.color + ' - ' + selectAId.size
              : selectAId.color
              ? selectAId.color
              : selectAId?.size && selectAId?.size}
            ){' '}
            {selectAId.stock < 1 && (
              <Chip
                sx={{ ml: 1 }}
                size="small"
                label="Нет в наличии"
                color="error"
              />
            )}
          </Typography>
          {!!(cartItem?.aIDs?.length > 1) && (
            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Выберите вариацию
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectAId}
                label="Выберите вариацию"
                onChange={({ target: { value } }) => setSelectAId(value)}
              >
                {cartItem?.aIDs
                  ?.sort((a, b) => (a.stock >= b.stock ? -1 : 1))
                  ?.map((a) => (
                    <MenuItem value={a} key={a.aID}>
                      {a.color && a.size
                        ? a.color + ' - ' + a.size
                        : a.color
                        ? a.color
                        : a?.size && a?.size}

                      {a.stock < 1 && (
                        <Chip
                          sx={{ ml: 1 }}
                          size="small"
                          label="Нет в наличии"
                          color="error"
                        />
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <Stack direction="row" spacing={2} alignItems="top" sx={{ mt: 3 }}>
            <div>
              <Typography as="p">Количество</Typography>

              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton
                  disabled={cartItem?.count == 0}
                  onClick={() =>
                    setCartItem({
                      ...cartItem,
                      count: cartItem?.count > 0 ? cartItem?.count - 1 : 0,
                    })
                  }
                >
                  -
                </IconButton>
                <Typography as="p" variant="h6">
                  {cartItem?.count}
                </Typography>
                <IconButton
                  disabled={cartItem?.count >= selectAId?.stock}
                  onClick={() =>
                    setCartItem({ ...cartItem, count: cartItem?.count + 1 })
                  }
                >
                  +
                </IconButton>
              </Stack>
            </div>
            <div style={{ paddingLeft: '20px', borderLeft: 'solid 1px #ddd' }}>
              <Typography as="p">Сумма</Typography>
              <Typography as="p" variant="h6" sx={{ mt: 1 }}>
                {cartItem?.retailPrice * cartItem?.count} руб.
              </Typography>
            </div>
          </Stack>
          {AddItemToCartApi?.error && (
            <Alert color="error" sx={{ mt: 2 }}>
              {AddItemToCartApi?.error}
            </Alert>
          )}

          <LoadingButton
            loading={AddItemToCartApi?.loading}
            disabled={
              disabled ||
              cartItem?.count == 0 ||
              cartItem?.count > selectAId?.stock
            }
            onClick={() => AddToCartHandler()}
            variant={variant}
            color={color}
            size="large"
            fullWidth
            sx={{ mt: 3 }}
          >
            Добавить в корзину
          </LoadingButton>
        </Box>
      </SwipeableDrawer>
    </>
  );
};
