import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

export const ProfileSupportPage = () => {
  return (
    <MainLayout activeNavigate={url?.profile} title="Поддержка">
      <Box sx={{ width: '250px', height: '250px', margin: '0 auto' }}>
        <img
          src="/support.png"
          style={{ objectFit: 'cover', width: '100%', height: ' 100%' }}
        />
      </Box>
      <Alert>
        <AlertTitle>Задайте свой вопрос</AlertTitle>
        <Typography>
          Задайте свой вопрос в телеграм боте. Просто напишите сообщении боту и
          мы ответим в ближайшее время. Средний срок ответа за запрос до 24
          часов.
        </Typography>
      </Alert>

      <Alert severity="info" sx={{ mt: 2 }}>
        <AlertTitle>Для официальных запросов</AlertTitle>
        <Typography>
          Для официальных запросов сотрудничества или юридических споров просьба
          писать на официальную потчу info@pinkieshop.ru
        </Typography>
      </Alert>

      <Typography as="h2" variant="h6" sx={{ mt: 3 }}>
        Частые вопросы
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Как я могу получить заказ?
        </AccordionSummary>
        <AccordionDetails>
          При совершении заказа, вы можете выбрать способ доставки - доставка
          СДЭК до двери, доставка СДЭК до пункта выдачи, доставка курьером по
          Москве или самовывоз со склада. После совершения заказа с вами
          свяжется наш администратор и подтвердит условия доставки. Если заказ
          совершен через СДЭК или курьера магазина, то с вами дополнительно
          свяжется сотрудник доставки и подтвердит время.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Как я могу оплатить заказ?
        </AccordionSummary>
        <AccordionDetails>
          В нашем интеренет магазине все заказы и доставка оплачиваются сразу
          при оформлении в 100% объеме.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Какие личные данные вы собираете?
        </AccordionSummary>
        <AccordionDetails>
          При первом использовании бота мы создаем ваш профиль, для этого мы
          собираем ваш nickname телеграма и chat_id в боте. По этим данным наша
          система создает шифрованный токен авторизации, благодаря которому в
          вашем аккаунте сохраняется история просмотров, корзина, вишлисты и
          создаются заказы. Мы также собираем дополнительную информацию - номер
          телефона, email, адрес, имя и фамилию при оформлении заказа. Эти
          данные мы используем чтобы совершить доставку и приянть оплату, мы не
          передаем данные посторонним людям и организациям. На свое усмотрение
          вы можете заполнить данные в профиле, это позволит нам делать для вас
          выгодные предложения.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Как я могу удалить данные из вашей системы?
        </AccordionSummary>
        <AccordionDetails>
          Если вы хотите полностью удалить свои данные из базы нашего сайта,
          просто напишите нам в телеграм бот. Наш администратор удалить ваш
          профиль, историю заказов, платежей и доставок, а так же все остальные
          данные, которые были связанны с вашим аккаунтом.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Как отменить заказ?
        </AccordionSummary>
        <AccordionDetails>
          Для отмены заказа просто напишите нам в чат бот с указанием номера
          заказа, наш администратор более детально проконсультирует об условиях
          и сроках вовзврата данного заказа
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Могу ли я отказаться от заказа при получении?
        </AccordionSummary>
        <AccordionDetails>
          Да, вы можете отказаться от заказа при получении. Мы вернем стоимость
          заказа, но не сможем вренуть стоимость доставки.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Вы передаете кому нибудь информацию о заказе и мои данные?
        </AccordionSummary>
        <AccordionDetails>
          Мы собираем и обрабатываем ваши данные только для работы магазина,
          аналитических данных и формирования рекламных, акционных и
          маркетенговых предложений. Ваши персональные данные также передаются в
          службу доставки и банк - это обязательные данные для совершения
          заказа. Более подробно вы можете проочитать в{' '}
          <Link to={url.profileOfferta}>офрете</Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Можно ли оформить доставку через СДЭК анонимно?
        </AccordionSummary>
        <AccordionDetails>
          К сожалению нет. СДЭК требует данные получателя заказа и информацию о
          перевозимом грузе. Если вы хотите сделать анонимный заказ,
          воспользуйтесь самовывозом или анонимной доставкой по Москве.
        </AccordionDetails>
      </Accordion>
    </MainLayout>
  );
};
