import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetCdekDeliveryPoints = async ({ jwt, body }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/cdek/delivery-points`,
    body,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetCdekLocation = async ({ jwt, body }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/cdek/location-cities`,
    body,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetCdekCalculatorTariff = async ({ jwt, body }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/cdek/calculator-tariff`,
    body,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};
