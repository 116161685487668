import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const CreateWishlistFromTelegram = async ({ jwt, product }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/wishlist/create-from-telegram`,
    {
      product,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const RemoveWishlistFromTelegram = async ({ jwt, productId }) => {
  const { data } = await axios.delete(
    `${BACKEND_URL}/v1/wishlist/remove-from-telegram/${productId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetMyWishlist = async ({ jwt, limit, offset }) => {
  const { data } = await axios.delete(`${BACKEND_URL}/v1/wishlist/get-my`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};
