import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';

export const ProductCategoriesItem = ({ category, fontSize = '18px' }) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(`/shop/${category?.slug}`)}
      sx={{
        width: '100%',
        height: '200px',
        position: 'relative',
        borderRadius: '10px',
        overflow: 'hidden !important',
        background: '#fff',
      }}
    >
      <Box
        sx={{
          mb: 2,
          mt: 1,
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 1,
        }}
      >
        {/* <Chip
          size="small"
          sx={{
            fontSize: '11px',
            background: '#9a528f !important',
            color: '#fff !important',
          }}
          label={'Товаров: ' + category?.productCount || 0}
        /> */}
      </Box>
      <img
        alt={category?.name}
        src={category?.picture?.url}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
      <div
        style={{
          width: '100%',
          height: '100%',
          background: `linear-gradient(24deg, #9b01a1 0%,   transparent 60%)`,
          position: 'absolute',
          bottom: '0',

          left: 0,
        }}
      >
        <Stack
          justifyContent="end"
          sx={{ width: '100%', height: '100%', p: 2, mb: 3 }}
        >
          <Typography
            sx={{
              mb: 4,
              fontSize,
              textShadow: '0 0 40px rgba(101, 1, 111, 1)',
              color: '#fff',
              lineHeight: '1.1',
              fontWeight: 'bold',
              width: '70%',
            }}
          >
            {category?.name}
          </Typography>
        </Stack>
      </div>
    </Box>
  );
};
