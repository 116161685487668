import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetAllProductsByCategoryId = async ({
  where,
  limit,
  offset,
  relations,
  jwt,
}) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/product/get-all`, {
    params: {
      where,
      limit,
      offset,
      relations,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};

export const GetProductViewed = async ({ jwt }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/product/get-product-viewed`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const SetProductViewed = async ({ jwt, body }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/product/set-product-viewed`,
    body,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetOneProductByAlias = async ({ relations, alias, jwt }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/product/get-one-by-alias/${alias}`,
    {
      params: {
        relations,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const CheckProductStock = async ({ variationIds }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/product/check-product-stock`,
    { variationIds },
  );
  return data;
};

export const GetFilterParams = async () => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/product/filter-params`);
  return data;
};

export const ProductFilter = async (filterData) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/product/filter`, {
    params: filterData,
  });
  return data;
};
