import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Drawer,
  Stack,
  Typography,
} from '@mui/material';
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import React, { useState } from 'react';

export const YandexMap = ({
  center,
  features,
  open,
  closeHandler,
  pickPointHandler,
  children,
}) => {
  const [selectedPoint, setSelectedPoint] = useState(null);

  return (
    <>
      <Drawer anchor={'bottom'} open={open} onClose={closeHandler}>
        {features?.length ? (
          <>
            <Card sx={{ width: '100%', height: '80vh' }}>
              <YMaps>
                <Map
                  width="100%"
                  height="100%"
                  defaultState={{
                    center: center,
                    zoom: 10,
                    controls: ['zoomControl'],
                  }}
                  modules={['control.ZoomControl']}
                >
                  {!!features?.length &&
                    features?.map((f, i) => (
                      <Placemark
                        onClick={() => {
                          setSelectedPoint(f);
                        }}
                        key={i}
                        defaultGeometry={center.map(
                          (c) => c + (Math.random() - 0.5),
                        )}
                        geometry={[f.location.latitude, f.location.longitude]}
                        options={{
                          iconImageSize: [10, 10],
                          preset: 'islands#pinkIcon',
                        }}
                      />
                    ))}
                </Map>
              </YMaps>
            </Card>
            <Card
              sx={{
                position: 'absolute',
                maxHeight: '50vh',
                bottom: 0,
                left: 0,
                width: '100%',
              }}
            >
              <CardContent>
                {selectedPoint ? (
                  <>
                    <Typography fontWeight="bold">
                      {selectedPoint?.location?.address_full}
                    </Typography>
                    <Typography fontWeight="bold" sx={{ mt: 2 }}>
                      Режим работы
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Понедельник
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 1,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Вторник
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 2,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Среда
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 3,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Четверг.
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 4,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                    </Stack>
                    <Stack direction="row" spacing={3} sx={{ mt: 1 }}>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Пятница
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 5,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Суббота
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 6,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            display: 'block',
                            opacity: 0.6,
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Воскресенье
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          {selectedPoint?.work_time_list?.find(
                            (tl) => tl?.day == 7,
                          )?.time || 'Вых.'}
                        </Typography>
                      </div>
                    </Stack>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => {
                        pickPointHandler({
                          point: selectedPoint,
                        });
                        closeHandler();
                        setSelectedPoint(null);
                      }}
                    >
                      Выбрать этот пункт
                    </Button>
                  </>
                ) : (
                  <Alert color="warning" variant="standard">
                    <AlertTitle>Выберите ПВЗ или постомат на карте</AlertTitle>
                  </Alert>
                )}
              </CardContent>
            </Card>
          </>
        ) : (
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        )}
      </Drawer>
    </>
  );
};
