// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertList_AlertsList__WhJmv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.AlertList_AlertsList__Item__jj\\+6M {
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  position: fixed;
  width: 98%;
}
.AlertList_AlertsList__Item__Remove__hP4us {
  transform: translateX(100px, 2s);
}`, "",{"version":3,"sources":["webpack://./src/components/Default/AlertList/AlertList.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;AACJ;AAAI;EACI,mBAAA;EACA,kDAAA;EACA,eAAA;EACA,UAAA;AAER;AADQ;EACI,gCAAA;AAGZ","sourcesContent":[".AlertsList {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    z-index: 9999;\n    &__Item {\n        margin-bottom: 10px;\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;\n        position: fixed;\n        width: 98%;\n        &__Remove {\n            transform: translateX(100px, 2s);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlertsList": `AlertList_AlertsList__WhJmv`,
	"AlertsList__Item": `AlertList_AlertsList__Item__jj+6M`,
	"AlertsList__Item__Remove": `AlertList_AlertsList__Item__Remove__hP4us`
};
export default ___CSS_LOADER_EXPORT___;
