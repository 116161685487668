import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { GetAddressData } from '../../api/DadataApi';
import { useCookies } from 'react-cookie';
import {
  GetCdekCalculatorTariff,
  GetCdekDeliveryPoints,
  GetCdekLocation,
} from '../../api/CdekApi';
import { YandexMap } from './YandexMap';

export const CdekDeliveryToPVZ = ({
  deliveryVariant,
  selectAddressHandler,
  order,
  focus,
  setFocus,
}) => {
  const [homeAddress, setHomeAddress] = useState({
    city: '',
    zip: '',
  });
  const [openYandexMap, setOpenYandexMap] = useState(false);
  const [dadataAddresses, setDadataAddresses] = useState([]);
  const [openHomeAddressDrawer, setOpenHomeAddressDrawer] = useState(false);
  const [cdekLocation, setCdekLocation] = useState(null);
  const [cdekLocationError, setCdekLocationError] = useState(false);
  const [cdekDeliveryPoints, setCdekDeliveryPoints] = useState([]);

  const [cookie] = useCookies('jwt');
  const GetAddressDataApi = useApi(GetAddressData);
  const GetCdekLocationApi = useApi(GetCdekLocation);
  const GetCdekCalculatorTariffApi = useApi(GetCdekCalculatorTariff);
  const GetCdekDeliveryPointsApi = useApi(GetCdekDeliveryPoints);

  const changeAddress = async (value) => {
    GetAddressDataApi.sendRequest({
      jwt: cookie.jwt,
      query: value,
    }).then((result) => {
      for (const address of result) {
        if (
          address.data &&
          address.data.postal_code &&
          !dadataAddresses?.some(
            (da) => da?.data?.region_kladr_id == address.data.region_kladr_id,
          )
        ) {
          setDadataAddresses([...dadataAddresses, address]);
        }
      }
    });
  };

  const mapSelectAddressToCdekAddress = async (address) => {
    setCdekLocationError(false);
    const location = await GetCdekLocationApi.sendRequest({
      jwt: cookie.jwt,
      body: {
        country_codes: [address?.data?.country_iso_code],
        postal_code: address?.data?.postal_code,
        city: address?.data?.city,
      },
    });
    const deliveryPoints = await GetCdekDeliveryPointsApi.sendRequest({
      jwt: cookie.jwt,
      body: {
        country_code: address?.data?.country_iso_code,
        postal_code: address?.data?.postal_code,
        city_code: location[0].code,
        type: deliveryVariant == 'pvz' ? 'PVZ' : 'POSTAMAT',
      },
    });

    setCdekDeliveryPoints(deliveryPoints);
    setOpenYandexMap(true);
    setDadataAddresses([]);
    setOpenHomeAddressDrawer(false);
    setCdekLocation(location[0]);
  };

  const CalculateDelivery = async ({ address, zip, pickUpId, addressFull }) => {
    const packages = order?.products?.map((p) => ({
      height: +p?.product?.parameters?.height || 0,
      length: +p?.product?.parameters?.length || 0,
      weight: +p?.product?.parameters?.brutto || 0,
      width: +p?.product?.parameters?.width || 0,
    }));
    const result = await GetCdekCalculatorTariffApi.sendRequest({
      body: {
        tariff_code: deliveryVariant == 'pvz' ? 136 : 137,
        from_location: {
          code: 44,
        },
        to_location: {
          code: address.code,
        },
        services: [
          {
            code: 'CARTON_BOX_XS',
            parameter: '2',
          },
        ],
        packages: packages,
      },
    });

    selectAddressHandler({
      deliveryType: 'CDEK',
      deliveryVariant: deliveryVariant,
      toLocationCode: address.code,
      fromLocationCode: 44,
      toLocationString: addressFull,
      fromLocationString: 'Москва',
      deliveryTotalSum: result?.total_sum,
      city: address.city,
      country: address.country,
      pickUpId,
      zip,
      packType: 2,
      calendarMin: result.calendar_min,
      calendarMax: result.calendar_max,
    });
  };

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={homeAddress?.city}
          label="Город"
          onChange={({ target: { value } }) =>
            setHomeAddress({ ...homeAddress, city: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={homeAddress?.zip}
          label="Индекс"
          onChange={({ target: { value } }) =>
            setHomeAddress({ ...homeAddress, zip: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Stack>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
        disabled={Object.values(homeAddress)?.some((ha) => !ha)}
        onClick={async () => {
          await changeAddress(`${homeAddress?.zip}, ${homeAddress?.city}`);
          setOpenHomeAddressDrawer(true);
        }}
      >
        Подтвердить адрес
      </Button>

      <Drawer
        anchor={'bottom'}
        open={openHomeAddressDrawer}
        onClose={() => setOpenHomeAddressDrawer(false)}
      >
        <Box sx={{ p: 3 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            {GetCdekLocationApi.loading && (
              <CircularProgress
                size="small"
                sx={{ width: '15px', height: '15px' }}
              />
            )}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Пожалуйста, уточните адрес
            </Typography>
          </Stack>

          {cdekLocationError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              К сожалению доставка в этот регион не осуществляется
            </Alert>
          )}

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            onChange={({ target: { value } }) => {
              mapSelectAddressToCdekAddress(JSON.parse(value));
            }}
            name="radio-buttons-group"
          >
            {dadataAddresses?.map((address) => (
              <FormControlLabel
                key={address.unrestricted_value}
                value={JSON.stringify(address)}
                disabled={GetCdekLocationApi.loading}
                control={<Radio />}
                label={`${address?.data?.country}, ${address?.data?.region_with_type}`}
              />
            ))}
          </RadioGroup>
        </Box>
      </Drawer>

      {openYandexMap && (
        <YandexMap
          open={openYandexMap}
          closeHandler={() => setOpenYandexMap(false)}
          center={[cdekLocation?.latitude, cdekLocation?.longitude]}
          features={cdekDeliveryPoints}
          pickPointHandler={(data) => {
            CalculateDelivery({
              zip: data.point.location?.postal_code,
              pickUpId: data.point.code,
              address: cdekLocation,
              addressFull: data.point.location.address_full,
            });
          }}
        >
          <Alert sx={{ mt: 2 }} color="error" severity="error">
            В данном регионе нет{' '}
            {deliveryVariant == 'pvz' ? 'пунктов выдачи' : 'постоматов'},
            попробуйте выбрать другой способ доставки или регион
          </Alert>
        </YandexMap>
      )}
    </>
  );
};
