import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const defaultResponseParser = (response) => response;

export const useApi = (axiosMethod, responseParser = defaultResponseParser) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      window.location = '/500';
    }
  }, [error]);
  const sendRequest = useCallback(
    async (...params) => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosMethod(...params);
        const result = responseParser(response);
        setData(result);
        return result;
      } catch ({
        response: {
          data: { message },
        },
      }) {
        setError(message);
      } finally {
        setLoading(false);
      }
    },
    [axiosMethod, responseParser],
  );

  return { data, error, loading, sendRequest };
};
