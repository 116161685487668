import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { useMe } from '../hooks/useMe';
import { CartItem } from '../components/Cart/CartItem';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Drawer,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useApi } from '../hooks/useApi';
import { CheckProductStock } from '../api/ProductApi';
import { LoadingButton } from '@mui/lab';
import { UpdateItemToCart } from '../api/CartApi';
import { useCookies } from 'react-cookie';
import { url } from '../utils/url';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useOrder } from '../hooks/useOrder';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';

export const CartPage = () => {
  const { customer, requestMeHandler } = useMe();
  const {
    order,
    RemoveLastOldOrdersHandler,
    CreateToOrderHandler,
    FindLastNotFinishedOrderHandler,
    GoToOrderStep,
    realProductOrderStock,
    productOrderStockError,
    orderLoading,
  } = useOrder({ customer, requestMeHandler });
  const [cart, setCart] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartSum, setCartSum] = useState(0);
  const [realProductStock, setRealProductStock] = useState([]);

  const CheckProductStockApi = useApi(CheckProductStock);
  const [productStockError, setProductStockError] = useState(false);

  const [removeAnimationId, setRemoveAnimationId] = useState(null);
  const [showMeOldOrder, setShowMeOldOrder] = useState(false);

  const UpdateItemToCartApi = useApi(UpdateItemToCart);
  const [cookie] = useCookies('jwt');
  const navigate = useNavigate();

  useEffect(() => {
    setCartCount(cart?.map((ci) => ci.count)?.reduce((a, b) => a + b, 0));
    setCartSum(
      cart
        ?.map((ci) => +ci.retailPrice * ci?.count)
        ?.reduce((a, b) => a + b, 0),
    );
    CheckProductStockApi.sendRequest({
      variationIds: cart
        ?.filter((ci) => ci?.product?.variations?.length)
        ?.map((ci) => ci?.product?.variations[0]?.id),
    }).then((result) => {
      const pError = [];
      for (const ci of cart) {
        const countCandidate = result?.find(
          (r) => r.id == ci?.product?.variations[0]?.id,
        )?.stock;
        if (+countCandidate < ci?.count) {
          pError.push(true);
        } else {
          pError.push(false);
        }
      }
      setProductStockError(pError.some((e) => e));
      setRealProductStock(result);
    });
  }, [cart]);

  useEffect(() => {
    FindLastNotFinishedOrderHandler();
    if (customer?.cart?.length) {
      setCart(customer?.cart);
    }
  }, [customer]);
  const dispatch = useDispatch();
  const updateCartItem = async (ci) => {
    const newcart = cart?.map((c) => {
      if (c.id == ci.id) {
        c = ci;
      }
      return c;
    });
    const removeCandidate = newcart?.find((ci) => ci?.count == 0);
    if (removeCandidate) {
      setRemoveAnimationId(removeCandidate?.id);
      setTimeout(() => {
        setCart(newcart?.filter((ci) => ci?.id !== removeCandidate?.id));
      }, 520);
      dispatch(setAlert({ text: 'Товар удален из корзины', status: 200 }));
    } else {
      setCart(newcart);
    }
    await UpdateItemToCartApi.sendRequest({
      jwt: cookie?.jwt,
      cartItem: ci,
    });
  };

  return (
    <MainLayout activeNavigate={url?.cart} title="Корзина">
      {order?.id ? (
        <Box
          sx={{
            pt: 7,
            pb: productStockError ? 25 : 15,
          }}
        >
          <Card variant="outlined">
            <CardContent>
              <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                У вас уже есть незавершенный заказ. Хотите продолжить?
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 3 }}
                justifyContent="center"
              >
                <LoadingButton
                  variant="outlined"
                  fullWidth
                  onClick={RemoveLastOldOrdersHandler}
                  loading={orderLoading}
                >
                  Новый заказ
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  loading={orderLoading}
                  disabled={productOrderStockError}
                  onClick={GoToOrderStep}
                >
                  Продолжить
                </LoadingButton>
              </Stack>
              <Divider sx={{ my: 2 }} />

              <Button
                variant="contained"
                fullWidth
                color="secondary"
                onClick={() => setShowMeOldOrder(true)}
              >
                Посмотреть заказ
              </Button>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            pt: 3,
            pb: productStockError ? 25 : 15,
          }}
        >
          {!!cart?.length ? (
            cart?.map((cartItem) => (
              <CartItem
                removeAnimationId={removeAnimationId}
                realProductStock={
                  realProductStock?.find(
                    (rps) =>
                      cartItem?.product?.variations?.length &&
                      rps?.id == cartItem?.product?.variations[0]?.id,
                  )?.stock || 0
                }
                cartItem={cartItem}
                setCartItem={(data) => updateCartItem(data)}
              />
            ))
          ) : (
            <>
              <Typography>Вы еще не добавили товары в корзину</Typography>
              <Button
                sx={{ mt: 3 }}
                color="primary"
                variant="contained"
                onClick={() => navigate(url.home)}
              >
                Перейти в каталог
              </Button>
            </>
          )}

          {!!cart?.length && (
            <AppBar
              position="fixed"
              color="inherit"
              sx={{ top: 'auto', bottom: 60, pt: 1, pb: 2 }}
            >
              <Toolbar
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Stack sx={{ width: '100%' }}>
                  <Typography as="p" variant="h6">
                    Итого
                  </Typography>
                  <Typography>
                    Товаров:{' '}
                    <Typography as="span" color="primary">
                      {cartCount} шт.
                    </Typography>{' '}
                    на сумму{' '}
                    <Typography as="span" color="primary">
                      {cartSum} руб.
                    </Typography>
                  </Typography>
                  {productStockError && (
                    <Alert color="error" sx={{ mt: 2 }}>
                      Сейчас не все выбранные товары доступны для покупк.
                      Проверьте товары в корзине
                    </Alert>
                  )}
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    disabled={productStockError}
                    onClick={() => {
                      CreateToOrderHandler({ cart });
                    }}
                    loading={CheckProductStockApi?.loading || orderLoading}
                    fullWidth
                  >
                    Перейти к оформлению
                  </LoadingButton>
                </Stack>
              </Toolbar>
            </AppBar>
          )}
        </Box>
      )}

      <Drawer
        anchor={'bottom'}
        open={showMeOldOrder}
        onClose={() => setShowMeOldOrder(false)}
      >
        <Box sx={{ p: 3 }}>
          {order?.created && (
            <Typography sx={{ fontWeight: 'bold', mb: 3 }} variant="h6">
              Заказ от {format(order?.created, 'dd.MM.yyyy')}
            </Typography>
          )}
          {order?.products?.map((cartItem) => (
            <CartItem
              removeAnimationId={removeAnimationId}
              realProductStock={
                realProductOrderStock?.find(
                  (rps) =>
                    cartItem?.product?.variations?.length &&
                    rps?.id == cartItem?.product?.variations[0]?.id,
                )?.stock || 0
              }
              cartItem={cartItem}
              showCount={false}
              setCartItem={(data) => updateCartItem(data)}
            />
          ))}
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 3 }}
            justifyContent="center"
          >
            <LoadingButton
              variant="outlined"
              fullWidth
              onClick={RemoveLastOldOrdersHandler}
              loading={orderLoading}
            >
              Новый заказ
            </LoadingButton>
            <LoadingButton
              variant="contained"
              fullWidth
              disabled={!!productOrderStockError}
              loading={orderLoading}
              onClick={GoToOrderStep}
            >
              Продолжить
            </LoadingButton>
          </Stack>
        </Box>
      </Drawer>
    </MainLayout>
  );
};
