import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';

export const SmallBanner = ({
  title,
  description,
  image,
  color,
  background,
}) => {
  return (
    <>
      <Card sx={{ background }}>
        <CardContent sx={{ p: 1, pb: '16px !important' }}>
          <Stack sx={{ justifyContent: 'space-around' }}>
            <Box sx={{ width: '100%', height: '70px' }}>
              <img src={image} />
            </Box>
            <Typography
              as="p"
              variant="caption"
              sx={{ fontWeight: 'bold', lineHeight: 1, mt: 2, mb: 0, pb: 0 }}
            >
              {title}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
