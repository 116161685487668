import React from 'react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useSwiper } from 'swiper/react';
import { Fab } from '@mui/material';

export const NextSliderButton = ({ top = '45%' }) => {
  const swiper = useSwiper();
  return (
    <Fab
      color="primary"
      sx={{
        position: 'absolute',
        right: '5px',
        zIndex: 99,
        top,
        width: '40px',
        height: '40px',
      }}
      onClick={() => swiper.slideNext()}
    >
      <ArrowForwardIosOutlinedIcon sx={{ width: '12px' }} />
    </Fab>
  );
};
