export const colorMapper = (color) => {
  const colors = color.toLowerCase(color).split(' ');

  const mockColors = [
    ['зебра', '#fff'],
    ['черный', '#000'],
    ['голубым', '#00BFFF'],
    ['зеленый', '#008000'],
    ['белым', '#fff'],
    ['коричневый', '#804030'],
    ['бежевым', '#FFEFDF'],
    ['кремовый', '#FFFDD0'],
    ['черным', '#000'],
    ['синий', '#0000ff'],
    ['камуфляж', '#C3B091'],
    ['белый', '#fff'],
    ['голубой', '#0080FF'],
    ['светло-бордовый', '#bf6470'],
    ['желтый', '#FFFF00'],
    ['фиолетовым', '#8B00FF'],
    ['персиковый', '#ffe5b4'],
    ['темно-серый', '#A9A9A9'],
    ['нежно-голубой', '#3bf8e8'],
    ['какао', '#d2691e'],
    ['изумрудный', '#50C878'],
    ['розовый', '#F6768E'],
    ['леопард', '#f48700'],
    ['розовым', '#F6768E'],
    ['дымчатый', '#909090'],
    ['темно-синий', '#00008b'],
    ['серым', '#808080'],
    ['лаймовый', '#9EFD38'],
    ['салатовый', '#99ff99'],
    ['сиреневый', '#c8a2c8'],
    ['красный', '#FF0000'],
    ['зеленым', '#008000'],
    ['нежно-розовый', '#F4C2C2'],
    ['зеленая', '#008000'],
    ['шотландка', '#eee'],
    ['бежевый', '#FFEFDF'],
    ['оранжевым', '#FF6600'],
    ['серебристый', '#C0C0C0'],
    ['фиолетовый', '#8000FF'],
    ['розовая', '#FFC0CB'],
    ['бирюзовый', '#40E0D0'],
    ['терракотовый', '#CC4E5C'],
    ['бордовый', '#9B2D30'],
    ['сливовый', '#660066'],
    ['прозрачный', '#fff'],
    ['желтым', '#FFFF00'],
    ['оранжевый', '#FF8000'],
    ['серый', '#ccc'],
    ['мятный', '#3eb489'],
    ['лаймовым', '#9EFD38'],
    ['коралловым', '#FF7F50'],
    ['тигровый', '#cb7119'],
    ['синяя', '#0000ff'],
    ['красным', '#FF0000'],
    ['оливковый', '#808000'],
    ['светло-серый', '#DCDCDC'],
    ['леопардовым', '#e5cf8c'],
    ['золотистый', '#ffd700'],
    ['темно-зеленый', '#013220'],
    ['чёрным', '#000'],
    ['темно-бирюзовый', '#116062'],
    ['персиковым', '#ffe5b4'],
    ['хаки', '#C3B091'],
    ['телесный', '#f5f5dc'],
    ['лиловый', '#822680'],
    ['красная', '#FF0000'],
    ['ярко-розовый', '#fc0fc0'],
    ['карамель', '#85461e'],
    ['(темно-бежевый)', '#f5f5dc'],
    ['синим', '#004DFF'],
    ['кофейный', '#c99a5b'],
    ['молочный', '#fffdd0'],
    ['пудровый', '#fadadd'],
    ['разноцветный', '#CD5C5C'],
    ['золотистым', '#ffd700'],
    ['малиновый', '#b02e61'],
    ['грязно-розовый', '#c1285a'],
    ['деним', '#1560bd'],
    ['(грязно-синий)', '#6A5ACD'],
    ['сиреневым', '#c8a2c8'],
    ['серебристым', '#C0C0C0'],
    ['малиновым', '#b02e61'],
    ['аквамариновый', '#7FFFD4'],
    ['бронзовый', '#cd7f32'],
    ['коралловый', '#FF7F50'],
    ['черная', '#000'],
  ];
  const resultColors = new Set();
  for (const [textColor, hexColor] of mockColors) {
    if (colors?.includes(textColor.toLowerCase())) {
      resultColors.add(hexColor);
    }
  }
  return Array.from(resultColors);
};
