import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { Box, Stack, Typography } from '@mui/material';

export const ErrorPage = () => {
  return (
    <MainLayout title="" titleAs="h6" hiddenNavigate={false}>
      <Stack
        sx={{ width: '100%', height: '70vh' }}
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography as="h1" variant="h1">
            Ошибка
          </Typography>
          <Typography>При запросе произошла ошибка</Typography>
        </Box>
      </Stack>
    </MainLayout>
  );
};
