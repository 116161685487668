import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const CheckPromocode = async ({ jwt, body }) => {
  const { data } = await axios.post(`${BACKEND_URL}/v1/promocode/check`, body, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};
