import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useOrder } from '../../hooks/useOrder';
import { useLocation, useNavigate } from 'react-router-dom';

export const OrderStepper = ({ orderId }) => {
  const { currentOrderStep } = useOrder({});
  const navigate = useNavigate();
  const location = useLocation();

  const steps = [
    {
      key: 'products',
      name: 'Товары',
      url: `/order/${orderId}/products`,
    },
    {
      key: 'delivery',
      name: 'Доставки',
      url: `/order/${orderId}/delivery`,
    },
    {
      key: 'customer',
      name: 'Покупатель',
      url: `/order/${orderId}/customer`,
    },
    {
      key: 'final',
      name: 'Финал',
      url: `/order/${orderId}/final`,
    },
  ];
  return (
    <Card
      sx={{
        width: '100%',
        my: 3,
        borderRadius: '10px',
        boxShadow: 'none',
        border: 'none',
      }}
      variant="outlined"
    >
      <CardContent sx={{ px: 1, py: 2 }}>
        <Stepper
          activeStep={steps.findIndex(
            (s) => s.key == location.pathname.split('/').pop(),
          )}
          alternativeLabel
        >
          {steps.map((step) => {
            return (
              <Step key={step?.key} onClick={() => navigate(step.url)}>
                <StepLabel>
                  <Typography
                    sx={{ fontSize: '14px', textAlign: 'left' }}
                    as="span"
                  >
                    {step?.name}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </CardContent>
    </Card>
  );
};
