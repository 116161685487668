import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const CreateOrder = async ({ jwt, body }) => {
  const { data } = await axios.post(`${BACKEND_URL}/v1/order/create`, body, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};
export const FindLastNotFinishedOrder = async ({ jwt }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/find-last-not-finished-order`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};
export const RemoveLastOldOrders = async ({ jwt }) => {
  const { data } = await axios.delete(
    `${BACKEND_URL}/v1/order/remove-last-old-orders`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetOrderById = async ({ jwt, orderId }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/get-one-by-id/${orderId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetOrderPayment = async ({ jwt, orderId }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/get-payment-info/${orderId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const UpdateOrder = async ({ jwt, body }) => {
  const { data } = await axios.put(`${BACKEND_URL}/v1/order/update`, body, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};

export const ReservationOrder = async ({ jwt, orderId, promocode }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/order/reservation`,
    {
      orderId,
      promocode,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const SetOrderPromocode = async ({ jwt, orderId, promocode }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/order/set-promocode`,
    {
      orderId,
      promocode,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};
export const RejectOrderPromocode = async ({ jwt, orderId }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/order/reject-promocode`,
    {
      orderId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const EditProductCount = async ({
  jwt,

  orderProductId,
  variationId,
  count,
}) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/order/edit-product-count`,
    {
      orderProductId,
      variationId,
      count,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const GetMyOrders = async ({ jwt, limit, offset }) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/get-my`, {
    params: {
      limit,
      offset,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};
