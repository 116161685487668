import React, { useEffect, useState } from 'react';
import { useApi } from '../hooks/useApi';
import { GetOneCategoryByAlias } from '../api/ProductCategoriesApi';
import { useLocation, useParams } from 'react-router-dom';
import { ProductFilter } from '../api/ProductApi';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  CircularProgress,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { ProductCategoriesItem } from '../components/ProductCategories/ProductCategoriesItem';
import { ProductItem } from '../components/Product/ProductItem';
import { useNavigate } from 'react-router-dom';
import { useMe } from '../hooks/useMe';
import { url } from '../utils/url';
import { useQuery } from '../hooks/useQuery';
import querystring from 'querystring';
import { Search } from '../components/Search/Search';

export const ProductCategoryPage = () => {
  const { customer } = useMe();

  const [category, setCategory] = useState({});
  const [products, setProducts] = useState([]);
  const [defaultFilter, setDefaultFilter] = useState();
  const [productsCount, setProductsCount] = useState(0);
  const [page, setPage] = useState(1);
  const GetOneCategoryByAliasApi = useApi(GetOneCategoryByAlias);

  const { categoryAlias } = useParams();

  const ProductFilterApi = useApi(ProductFilter);
  const filterQueryParams = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (filterQueryParams) {
      const parseFilter = querystring.parse(filterQueryParams.toString());
      const arrayKeys = [
        'collectionName',
        'pack',
        'batteries',
        'labels',
        'material',
        'color',
        'size',
      ];
      for (const pf in parseFilter) {
        if (arrayKeys.includes(pf) && typeof parseFilter[pf] == 'string') {
          parseFilter[pf] = [parseFilter[pf]];
        }
      }
      setDefaultFilter({ ...parseFilter });
      getPageDataHandler(parseFilter);
    } else {
      getPageDataHandler();
    }
  }, [filterQueryParams, location]);

  const getPageDataHandler = (parseFilter = defaultFilter) => {
    if (categoryAlias) {
      GetOneCategoryByAliasApi.sendRequest({
        alias: categoryAlias,
        relations: ['children', 'parent', 'children'],
      }).then((result) => {
        setCategory(result);
        if (!result?.children?.length && result?.id) {
          ProductFilterApi.sendRequest({
            ...parseFilter,
            categorySlug: result?.slug,
          }).then((result) => {
            if (result?.length) {
              setProducts(result[0]);
              setProductsCount(result[1]);
            }
          });
        }
      });
    } else {
      ProductFilterApi.sendRequest({
        ...parseFilter,
      }).then((result) => {
        if (result?.length) {
          setProducts(result[0]);
          setProductsCount(result[1]);
        }
      });
    }
  };

  return (
    <MainLayout
      activeNavigate={url?.shop}
      title={category?.name || 'Поиск'}
      titleAs="h6"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Search
            limit={16}
            offset={page - 1}
            defaultFilter={defaultFilter}
            category={category}
          />
        </Grid>
        {category?.children?.length ? (
          category?.children?.map((cat) => (
            <Grid key={cat?.id} item xs={6}>
              <ProductCategoriesItem category={cat} />
            </Grid>
          ))
        ) : !ProductFilterApi?.loading ? (
          products?.length ? (
            products?.map((product) => (
              <Grid key={product?.id} item xs={6}>
                <ProductItem
                  product={product}
                  category={category}
                  customer={customer}
                  updateStockHandler={() => getPageDataHandler()}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography>Товары не найдены</Typography>
            </Grid>
          )
        ) : (
          [1, 2, 3, 4].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', height: '180px' }}
              />
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', height: '42px', mt: '15px' }}
              />
              <Skeleton
                variant="rounded"
                sx={{ width: '64px', height: '16.5px', mt: 1 }}
              />
              <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: '152px', height: '30.75px' }}
                />
                <Skeleton
                  variant="circular"
                  sx={{ width: '30.75px', height: '30.75px' }}
                />
              </Stack>
            </Grid>
          ))
        )}

        {!!productsCount && (
          <Grid item xs={12}>
            <Pagination
              sx={{ my: 5 }}
              count={Math.ceil(productsCount / 16)}
              variant="outlined"
              shape="rounded"
              onChange={(event, value) => {
                setPage(value);

                if (category?.id) {
                  navigate(
                    `/shop/${category?.slug}?${querystring.stringify({
                      ...defaultFilter,
                      offset: (value - 1) * 16,
                    })}`,
                  );
                } else {
                  navigate(
                    `/search?${querystring.stringify({
                      ...defaultFilter,
                      offset: (value - 1) * 16,
                    })}`,
                  );
                }
                getPageDataHandler({
                  ...defaultFilter,
                  offset: (value - 1) * 16,
                });
                //
              }}
            />
          </Grid>
        )}
      </Grid>
    </MainLayout>
  );
};
