import {
  Alert,
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RemoveAnimationPlaceholder } from '../Default/RemoveAnimationPlaceholder';
import { useNavigate } from 'react-router-dom';

export const CartItem = ({
  removeAnimationId,
  cartItem,
  setCartItem,
  realProductStock,
  showCount = true,
}) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ position: 'relative' }}>
      {removeAnimationId == cartItem?.id && (
        <RemoveAnimationPlaceholder
          startAnimation={removeAnimationId == cartItem?.id}
        />
      )}
      <Stack direction="row" spacing={2} alignItems="start">
        <Card
          sx={{
            width: '60px',
            height: '60px',
            overflow: 'hidden',
            borderRadius: '7px',
          }}
          onClick={() => navigate(cartItem?.productURL)}
        >
          <img
            alt={cartItem?.name}
            src={cartItem?.image || '/not-found-image.jpeg'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Card>
        <div
          style={{ width: '50%' }}
          onClick={() => navigate(cartItem?.productURL)}
        >
          <Typography
            as="p"
            sx={{ fontWeight: 'bold', lineHeight: '1.2', color: '#3d3d3d' }}
          >
            {cartItem?.name} (
            {cartItem.color && cartItem.size
              ? cartItem.color + ' - ' + cartItem.size
              : cartItem.color
              ? cartItem.color
              : cartItem?.size && cartItem?.size}
            )
          </Typography>
        </div>
        <Box sx={{ width: '100px' }}>
          <Typography as="p" color="primary">
            {cartItem?.retailPrice} руб.
          </Typography>
          {showCount && (
            <Stack direction="row" spacing="1" alignItems="center">
              <Box>
                <IconButton
                  disabled={cartItem?.count == 0}
                  onClick={() =>
                    setCartItem({
                      ...cartItem,
                      count: cartItem?.count > 0 ? cartItem?.count - 1 : 0,
                    })
                  }
                >
                  -
                </IconButton>
              </Box>

              <Typography as="p" variant="caption">
                {cartItem?.count} шт.
              </Typography>
              <Box>
                <IconButton
                  disabled={
                    cartItem?.count >= cartItem?.product?.variantion?.length
                      ? cartItem?.product?.variantion[0]?.stock
                      : 0
                  }
                  onClick={() =>
                    setCartItem({ ...cartItem, count: cartItem?.count + 1 })
                  }
                >
                  +
                </IconButton>
              </Box>
            </Stack>
          )}
        </Box>
      </Stack>
      {realProductStock < cartItem?.count && (
        <Alert variant="filled" color="error" sx={{ mt: 1 }}>
          Сейчас доступно к заказу только {realProductStock} шт.
        </Alert>
      )}
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};
