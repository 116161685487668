import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { Typography } from '@mui/material';
import { url } from '../utils/url';

export const ReturnsPage = () => {
  return (
    <MainLayout
      activeNavigate={url?.profile}
      title="Условия возврата, замены, гарантии"
      titleAs="h6"
      hiddenNavigate={false}
    >
      <Typography sx={{ mb: 2 }} as="h2" variant="h5">
        Возврат, замена и гарантии
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Несмотря на то, что бракованные товары составляют менее одного процента
        от всего объема продаж, мы считаем необходимым проверять
        работоспособность каждой модели, чтобы исключить вероятность покупки
        бракованного товара. Перед отправкой покупателю все товары, которые не
        имеют защиты от вскрытия (как, например, изделия из латекса),
        проверяются администратором на наличие брака. Кроме визуального контроля
        также тестируются электрические приборы - вибраторы. Поэтому мы можем
        быть уверены, что вы не получите бракованный товар.
      </Typography>

      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Если у Вас имеются претензии к качеству товара, купленного в нашем
        интернет-магазине, или возникла необходимость его возврата/обмена по
        каким-либо причинам, Вам следует связаться с нами через телеграм бот.
        Наши менеджеры, действуя в рамках Российского законодательства,
        регулирующего взаимоотношения между покупателями и продавцами, подскажут
        Вам, как действовать в той или иной ситуации, и постараются решить Вашу
        проблему в кратчайшие сроки.
      </Typography>

      <Typography sx={{ mb: 2 }} as="h2" variant="h5">
        Возврат товара ненадлежащего качества
      </Typography>

      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Мы сотрудничаем только с проверенными временем поставщикам, стараемся
        следить за качеством всей продукции, однако это не исключает возможности
        брака. По нашим подсчётам, бракованные товары составляют менее 1% от
        всего объема продаж.
      </Typography>
      <Typography sx={{ mb: 1, textAlign: 'justify' }} as="p" variant="p">
        Если Вы получили товар с производственным дефектом, по федеральному
        закону «О защите прав потребителей» Вы вправе:
      </Typography>
      <ul>
        <li>
          отказаться от товара и потребовать возврата уплаченной за товар
          денежной суммы;
        </li>
        <li>
          потребовать замены на товар аналогичной марки (модели, артикула);
        </li>
        <li>
          потребовать замены на такой же товар другой марки (модели, артикула) с
          соответствующим перерасчетом покупной цены;
        </li>
        <li>потребовать соразмерного уменьшения покупной цены;</li>
        <li>
          потребовать незамедлительного безвозмездного устранения недостатков
          товара.
        </li>
      </ul>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Для этого Вам следует связаться с нашими менеджерами через телеграм бот
        в течение 7 календарных дней с момента получения товара с дефектом –
        сообщить о проблеме, максимально подробно описать дефект, приложить
        фотографию повреждённого или не работающего товара.
      </Typography>
      <Typography sx={{ mb: 2 }} as="h3" variant="h6">
        ВАЖНО:
      </Typography>
      <ul>
        <li>
          По истечении 7 календарных дней с момента получения товара претензии
          по качеству не принимаются*.
        </li>
        <li>Товары, бывшие в употреблении, возврату не подлежат*.</li>
        <li>
          Транспортные расходы на возврат товара ненадлежащего качества
          оплачивает наш интернет-магазин*.
        </li>
      </ul>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        *исключение – товары, на которые установлен гарантийный срок.
      </Typography>

      <Typography sx={{ mb: 2 }} as="h2" variant="h5">
        Возврат товара надлежащего качества
      </Typography>

      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Весь ассортимент, представленный в нашем каталоге, входит в перечень
        непродовольственных товаров, не подлежащих возврату или обмену (ст.25
        п.1 закона РФ «О защите прав потребителей»). Это значит, что если
        купленный в нашем интернет-магазине товар надлежащего качества не
        подошёл Вам по размеру, форме, фасону, расцветке или комплектации, Вы не
        можете вернуть его нам или обменять на другой.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Однако в редких случаях мы идём навстречу покупателям и готовы обменять
        новый товар* на аналогичный другого
        размера/формы/расцветки/комплектации. Если по каким-то причинам товар
        Вас не устроил, и Вы хотите его обменять, в кратчайшие сроки после
        получения заказа свяжитесь с нашими менеджерами через телеграм бот.
        Важно: в случае обмена/возврата товара надлежащего качества Вы
        оплачиваете все транспортные расходы.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        * обмен/возврат товара надлежащего качества производится, если указанный
        товар не был в употреблении, сохранены его товарный вид, потребительские
        свойства, пломбы, фабричные ярлыки.
      </Typography>
    </MainLayout>
  );
};
