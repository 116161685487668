import {
  Alert,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { OrderFooter } from './OrderFooter';
import { useNavigate } from 'react-router-dom';
import { url } from '../../utils/url';

import { useApi } from '../../hooks/useApi';
import { GetAddressData } from '../../api/DadataApi';
import { useCookies } from 'react-cookie';
import { CheckMkadDeliveryPoint } from '../../utils/checkMkadDelivery';

export const CourierDeliveryScreen = ({
  order,
  UpdateOrderHandler,
  GoToOrderStep,
}) => {
  const [dadataAddresses, setDadataAddresses] = useState([]);
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState();
  const [writedCustomerAddress, setWritedCustomerAddress] = useState();
  const [deliveryError, setDeliveryError] = useState(false);
  const navigate = useNavigate();
  const GetAddressDataApi = useApi(GetAddressData);

  const [cookie] = useCookies('jwt');
  const changeAddress = async (value) => {
    setWritedCustomerAddress(value);
    GetAddressDataApi.sendRequest({
      jwt: cookie.jwt,
      query: value,
    }).then((result) => setDadataAddresses(result?.filter((r) => r.data)));
  };
  const selectAddressHandler = async (address) => {
    setWritedCustomerAddress(address?.unrestricted_value);
    setSelectedCustomerAddress(address);
    setDadataAddresses([]);
  };

  const dropDeliveryFromInterface = () => {
    setDadataAddresses(null);
    setSelectedCustomerAddress(null);

    setWritedCustomerAddress('');
  };

  return (
    <>
      <>
        <div style={{ width: '100%', position: 'relative' }}>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            value={writedCustomerAddress || ''}
            label={'Укажите ваш полный адрес'}
            onChange={({ target: { value } }) => changeAddress(value)}
          />

          {!!dadataAddresses?.length && (
            <Stack sx={{ position: 'absolute', zIndex: 1, pb: 20 }}>
              {dadataAddresses?.map((address) => (
                <Card
                  onClick={() => {
                    const mkadCheck = CheckMkadDeliveryPoint([
                      address.data.geo_lon,
                      address.data.geo_lat,
                    ]);
                    if (mkadCheck) {
                      selectAddressHandler(address);
                      setDeliveryError(false);
                    } else {
                      setDeliveryError(true);
                      setDadataAddresses([]);
                    }
                  }}
                  key={address.unrestricted_value}
                  sx={{ px: 2, py: 1, borderRadius: 0 }}
                >
                  <Typography>{address.unrestricted_value}</Typography>
                </Card>
              ))}
            </Stack>
          )}
        </div>
      </>

      {selectedCustomerAddress && (
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Typography fontWeight="bold">Адрес:</Typography>
            <Typography>{writedCustomerAddress}</Typography>

            <Typography sx={{ mt: 1 }} fontWeight="bold">
              Срок
            </Typography>
            <Typography>
              в течение одного дня с момента заказа, по будням с 10:00 до 19:00
            </Typography>
            <Typography sx={{ mt: 1 }} fontWeight="bold">
              Стоимость
            </Typography>
            <Typography>300 руб.</Typography>
            <Button
              variant="outlined"
              color="info"
              sx={{ mt: 2 }}
              fullWidth
              onClick={() => {
                dropDeliveryFromInterface();
              }}
            >
              Изменить
            </Button>
          </CardContent>
        </Card>
      )}

      {deliveryError && (
        <Alert severity="error" sx={{ mt: 3 }}>
          Доставка курьером по данному адресу не осуществляется. Доставка
          курьером возможна только в пределах МКАД
        </Alert>
      )}

      <OrderFooter
        nextButtonText="Продолжить"
        loading={GetAddressDataApi.loading}
        disabled={!selectedCustomerAddress}
        showBackButton={true}
        backButtonHandler={() => navigate(url.orderDelivery)}
        nextButtonHandler={async () => {
          let id = {};
          if (order?.delivery?.id) {
            id = {
              id: order?.delivery?.id,
            };
          }
          await UpdateOrderHandler({
            ...order,
            delivery: {
              deliveryType: 'COURIER',
              deliveryVariant: null,
              fromLocationCode: 44,
              toLocationString: writedCustomerAddress,
              fromLocationString: 'Москва',
              deliveryTotalSum: 300,
              toLocationCode: 44,
              ...id,
            },
          });
          GoToOrderStep();
        }}
      />
    </>
  );
};
