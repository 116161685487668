import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { useApi } from '../hooks/useApi';
import { GetAllCategoriesToCatalog } from '../api/ProductCategoriesApi';
import { Masonry, Skeleton } from '@mui/lab';
import { Search } from '../components/Search/Search';

import { ProductCategoriesItem } from '../components/ProductCategories/ProductCategoriesItem';
import { Grid } from '@mui/material';

export const CatalogPage = () => {
  const [categories, setCategories] = useState([]);
  const GetAllCategoriesToCatalogApi = useApi(GetAllCategoriesToCatalog);

  const GetAllPopularCategoriesHandler = async () => {
    const result = await GetAllCategoriesToCatalogApi.sendRequest();
    setCategories(result);
  };

  useEffect(() => {
    GetAllPopularCategoriesHandler();
  }, []);

  return (
    <MainLayout activeNavigate={url?.shop} title="Каталог" titleAs="h5">
      <Search limit={16} offset={0} />
      <Grid container spacing={2}>
        {categories?.length
          ? categories?.map((category, index) => (
              <Grid item xs={6}>
                <ProductCategoriesItem key={index} category={category} />
              </Grid>
            ))
          : [1, 2, 3, 4].map((i) => (
              <Grid item xs={6}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: '100%', height: '200px' }}
                />
              </Grid>
            ))}
      </Grid>
    </MainLayout>
  );
};
