import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const AddItemToCart = async ({ jwt, cartItem }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/cart/add-item`,
    cartItem,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const UpdateItemToCart = async ({ jwt, cartItem }) => {
  const { data } = await axios.put(
    `${BACKEND_URL}/v1/cart/update-item`,
    cartItem,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};
