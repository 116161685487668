import React, { useEffect, useState } from 'react';
import { OrderStepper } from '../components/Order/OrderStepper';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { useOrder } from '../hooks/useOrder';
import { OrderFooter } from '../components/Order/OrderFooter';
import { useNavigate } from 'react-router-dom';
import { OrderCustomerData } from '../components/Order/OrderCustomerData';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { isEmail, isMobilePhone } from 'validator';

export const OrderCustomerPage = () => {
  const { order, orderLoading, GoToOrderStep, UpdateOrderHandler } = useOrder(
    {},
  );
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const [customerVariant, setCustomerVariant] = useState('anonim');
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    if (order) {
      setCustomer(order?.customer);
    }

    if (order?.delivery?.deliveryType == 'CDEK') {
      setCustomerVariant('full');
    }
  }, [order]);

  useEffect(() => {
    if (Object.keys(customer)?.length) {
      if (customer?.email) {
        if (!isEmail(customer?.email)) {
          setError({ ...error, email: true });
        } else {
          setError({ ...error, email: false });
        }
      }
    }
  }, [customer]);
  return (
    <MainLayout
      activeNavigate={url?.cart}
      title="Оформление заказа"
      titleAs="h6"
      hiddenNavigate={true}
    >
      <Box
        sx={{
          height: focus ? `calc(${window?.screen?.height}px)` : 'auto',
        }}
      >
        <OrderStepper orderId={order?.id} />
        {order?.delivery?.deliveryType == 'SELF' && (
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Card
              variant="outlined"
              sx={
                customerVariant == 'anonim'
                  ? {
                      border: 'solid 1px #d15edc !important',
                      mb: 2,
                      width: '50%',
                    }
                  : { mb: 2, width: '50%' }
              }
              onClick={() => {
                setCustomerVariant('anonim');
              }}
            >
              <CardContent>
                <Typography>Анонимная покупка</Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={
                customerVariant == 'full'
                  ? {
                      border: 'solid 1px #d15edc !important',
                      mb: 2,
                      width: '50%',
                    }
                  : { mb: 2, width: '50%' }
              }
              onClick={() => {
                setCustomerVariant('full');
              }}
            >
              <CardContent>
                <Typography>Указать полные данные</Typography>
              </CardContent>
            </Card>
          </Stack>
        )}

        <OrderCustomerData
          customerVariant={customerVariant}
          orderCustomer={customer}
          setOrderCustomer={(data) => setCustomer(data)}
          focus={focus}
          setFocus={(data) => setFocus(data)}
          error={error}
        />
        {customerVariant == 'full' ? (
          <Alert severity="info" sx={{ mb: 10 }}>
            Ваши данные нужны для службы доставки. Мы не передаем ваши данные
            третьим лицам кроме службы доставки. Служба доставки не знает какие
            товары вы заказываете
          </Alert>
        ) : (
          <Alert severity="info" sx={{ mb: 10 }}>
            Ваши контактные данные нужны для подтверждения заказа. Мы не
            передаем их третьим лицам.
          </Alert>
        )}

        <OrderFooter
          nextButtonText="Продолжить"
          loading={orderLoading}
          disabled={
            !customer?.email ||
            !customer?.phone ||
            Object.values(error).some((e) => e)
          }
          showBackButton={true}
          backButtonHandler={() => navigate(`/order/${order?.id}/delivery`)}
          nextButtonHandler={async () => {
            let id = {};
            if (order?.customer?.id) {
              id = {
                id: order?.customer?.id,
              };
            }
            await UpdateOrderHandler({
              ...order,
              customer: { ...customer, ...id },
            });
            await GoToOrderStep();
          }}
        />
      </Box>
    </MainLayout>
  );
};
