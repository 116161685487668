import { Button, Grid, Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { useMe } from '../hooks/useMe';
import { ProductItem } from '../components/Product/ProductItem';
import { useApi } from '../hooks/useApi';
import { GetMyWishlist } from '../api/WishlistApi';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export const WishlistPage = () => {
  const [wishlist, setWishlist] = useState([]);
  const [page, setPage] = useState(1);
  const [wishlistCount, setWishlistCount] = useState(0);
  const { customer } = useMe();
  const GetMyWishlistApi = useApi(GetMyWishlist);
  const [cookie] = useCookies('jwt');
  const navigate = useNavigate();

  useEffect(() => {
    GetMyWishlistHandler();
  }, [customer]);

  const GetMyWishlistHandler = async (limit = 16, offset = page - 1) => {
    const result = await GetMyWishlistApi.sendRequest({
      jwt: cookie.jwt,
      offset,
      limit,
    });
    setWishlist(result[0]);
    setWishlistCount(result[1]);
  };
  return (
    <MainLayout activeNavigate={url?.wishlist} title="Избранное">
      <Grid container spacing={2}>
        {!!wishlist?.length ? (
          wishlist?.map((product) => (
            <Grid item xs={6}>
              <ProductItem
                product={product}
                customer={customer}
                category={product?.category}
                removeItemFromList={() => {
                  setWishlist([...wishlist.filter((w) => w.id !== product.id)]);
                }}
              />
            </Grid>
          ))
        ) : (
          <>
            <Typography>Вы еще не добавили товары в избранное</Typography>
            <Button
              sx={{ mt: 3 }}
              color="primary"
              variant="contained"
              onClick={() => navigate(url.home)}
            >
              Перейти в каталог
            </Button>
          </>
        )}
      </Grid>
      {Math.ceil(wishlistCount / 16) > 1 && (
        <Grid item xs={12}>
          <Pagination
            sx={{ my: 5 }}
            count={Math.ceil(wishlistCount / 16)}
            variant="outlined"
            shape="rounded"
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </Grid>
      )}
    </MainLayout>
  );
};
