import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from './useApi';
import {
  CreateOrder,
  FindLastNotFinishedOrder,
  GetOrderById,
  RejectOrderPromocode,
  RemoveLastOldOrders,
  ReservationOrder,
  SetOrderPromocode,
  UpdateOrder,
} from '../api/OrderApi';
import { CheckProductStock } from '../api/ProductApi';

export const useOrder = ({ customer, requestMeHandler }) => {
  const [cookie] = useCookies('jwt');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [realProductOrderStock, setRealProductOrderStock] = useState([]);
  const [productOrderStockError, setProductOrderStockError] = useState(false);
  const [currentOrderStep, setCurrentOrderStep] = useState('delivery');

  const CreateOrderAPi = useApi(CreateOrder);
  const FindLastNotFinishedOrderApi = useApi(FindLastNotFinishedOrder);
  const RemoveLastOldOrdersApi = useApi(RemoveLastOldOrders);
  const CheckProductStockApi = useApi(CheckProductStock);
  const GetOrderByIdApi = useApi(GetOrderById);
  const UpdateOrderApi = useApi(UpdateOrder);
  const ReservationOrderApi = useApi(ReservationOrder);
  const SetOrderPromocodeApi = useApi(SetOrderPromocode);
  const RejectOrderPromocodeApi = useApi(RejectOrderPromocode);

  const { orderId } = useParams();
  useEffect(() => {
    if (customer) {
      FindLastNotFinishedOrderHandler();
    }
  }, [customer]);

  useEffect(() => {
    if (orderId) {
      GetOrderByIdHandler({ orderId });
    }
  }, [orderId]);
  useEffect(() => {
    if (order) {
      setLoading(true);
      CheckProductStockApi.sendRequest({
        variationIds: order?.products
          ?.filter((ci) => ci?.product?.variations?.length)
          ?.map((ci) => ci?.product?.variations[0]?.id),
      }).then((result) => {
        const pError = [];
        for (const ci of order?.products) {
          const countCandidate = result?.find(
            (r) => r.id == ci?.product?.variations[0]?.id,
          )?.stock;
          if (+countCandidate < ci?.count) {
            pError.push(true);
          } else {
            pError.push(false);
          }
        }
        setProductOrderStockError(pError?.some((e) => e));
        setRealProductOrderStock(result);
        setLoading(false);
      });

      if (!order?.delivery) {
        setCurrentOrderStep('delivery');
      } else if (
        !order?.customer?.firstName ||
        !order?.customer.lastName ||
        !order?.customer?.phone
      ) {
        setCurrentOrderStep('customer');
      } else if (!order?.payment) {
        setCurrentOrderStep('final');
      }
    }
  }, [order]);

  const CreateToOrderHandler = async ({ cart }) => {
    try {
      const newOrder = await CreateOrderAPi.sendRequest({
        jwt: cookie?.jwt,
        body: {
          products: cart.map((item) => ({ ...item, cartItemId: item.id })),
        },
      });
      if (newOrder?.id) {
        navigate(`/order/${newOrder?.id}/delivery`);
      }
    } catch (error) {}
    setLoading(false);
  };
  const FindLastNotFinishedOrderHandler = async () => {
    const oldOrder = await FindLastNotFinishedOrderApi.sendRequest({
      jwt: cookie?.jwt,
    });
    setOrder(oldOrder);
  };

  const RemoveLastOldOrdersHandler = async () => {
    const removeResult = await RemoveLastOldOrdersApi.sendRequest({
      jwt: cookie?.jwt,
    });
    if (removeResult) {
      await requestMeHandler();
    }
  };

  const GoToOrderStep = async () => {
    const o = await GetOrderById({
      jwt: cookie?.jwt,
      orderId: order?.id,
    });

    if (!o.delivery) {
      navigate(`/order/${o.id}/delivery`);
    } else if (!o?.customer?.phone || !o?.customer?.email) {
      navigate(`/order/${o.id}/customer`);
    } else if (!o?.payment || o?.payment?.status === 'NEW') {
      navigate(`/order/${o.id}/final`);
    }
  };

  const UpdateOrderHandler = async (updateData) => {
    const result = await UpdateOrderApi.sendRequest({
      jwt: cookie?.jwt,
      body: updateData,
    });
    if (result) {
      await GetOrderByIdHandler({ orderId: updateData.id });
    }
  };

  const GetOrderByIdHandler = async ({ orderId }) => {
    if (orderId) {
      const result = await GetOrderByIdApi.sendRequest({
        jwt: cookie?.jwt,
        orderId,
      });
      setOrder(result);
    }
  };

  const ReservationOrderHandler = async ({ promocode = {} }) => {
    const result = await ReservationOrderApi.sendRequest({
      jwt: cookie?.jwt,
      orderId: order?.id,
      promocode,
    });

    return result;
  };

  const SetOrderPromocodeHandler = async ({ promocode = {} }) => {
    const result = await SetOrderPromocodeApi.sendRequest({
      jwt: cookie?.jwt,
      orderId: order?.id,
      promocode,
    });
    await GetOrderByIdHandler({ orderId: order.id });

    return result;
  };

  const RejectOrderPromocodeHandler = async () => {
    const result = await RejectOrderPromocodeApi.sendRequest({
      jwt: cookie?.jwt,
      orderId: order?.id,
    });
    await GetOrderByIdHandler({ orderId: order.id });

    return result;
  };

  return {
    order,
    RemoveLastOldOrdersHandler,
    FindLastNotFinishedOrderHandler,
    CreateToOrderHandler,
    GoToOrderStep,
    GetOrderByIdHandler,
    UpdateOrderHandler,
    ReservationOrderHandler,
    SetOrderPromocodeHandler,
    RejectOrderPromocodeHandler,
    realProductOrderStock,
    orderLoading: loading,
    productOrderStockError,
    currentOrderStep,
  };
};
