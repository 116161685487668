import React, { useEffect } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { OrderStepper } from '../components/Order/OrderStepper';
import { useParams } from 'react-router-dom';
import { useOrder } from '../hooks/useOrder';
import { CourierDeliveryScreen } from '../components/Order/CourierDeliveryScreen';

export const OrderCourierDeliveryPage = () => {
  const { orderId } = useParams();
  const { order, GetOrderByIdHandler, UpdateOrderHandler, GoToOrderStep } =
    useOrder({});

  useEffect(() => {
    if (orderId) {
      GetOrderByIdHandler({ orderId });
    }
  }, [orderId]);
  return (
    <MainLayout
      activeNavigate={url?.cart}
      title="Оформление заказа"
      titleAs="h6"
      hiddenNavigate={true}
    >
      <OrderStepper orderId={orderId} />
      <CourierDeliveryScreen
        order={order}
        UpdateOrderHandler={async (data) => await UpdateOrderHandler(data)}
        GoToOrderStep={GoToOrderStep}
      />
    </MainLayout>
  );
};
