import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useApi } from '../../hooks/useApi';
import { GetProductViewed } from '../../api/ProductApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductItem } from './ProductItem';
import { FreeMode } from 'swiper/modules';
import { Typography } from '@mui/material';
import { NextSliderButton } from '../Default/Slider/NextSliderButton';
import { PrevSliderButton } from '../Default/Slider/PrevSliderButton';

export const ProductViewed = ({ customer }) => {
  const [products, setProducts] = useState();
  const [cookie] = useCookies('jwt');
  const GetProductViewedApi = useApi(GetProductViewed);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    GetProductViewedApi.sendRequest({
      jwt: cookie.jwt,
    }).then((result) => setProducts(result));
  };
  return (
    !!products?.length && (
      <>
        <Typography
          as="h2"
          variant="h5"
          sx={{ fontWeight: 'bold', mb: 3, mt: 3 }}
        >
          Вы ранее смотрели
        </Typography>
        <Swiper
          slidesPerView={2}
          spaceBetween={15}
          freeMode={true}
          loop={true}
          modules={[FreeMode]}
          className="mySwiper"
        >
          <PrevSliderButton top="20%" />
          {products?.map((product) => (
            <SwiperSlide
              key={product.id}
              style={{
                overflow: 'hidden',
                background: 'none',
                textAlign: 'left',
              }}
            >
              <ProductItem
                product={product}
                category={product?.category}
                customer={customer}
                updateStockHandler={() => getProduct()}
              />
            </SwiperSlide>
          ))}
          <NextSliderButton top="20%" />
        </Swiper>
      </>
    )
  );
};
