import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const UseRulesPage = () => {
  return (
    <MainLayout
      activeNavigate={url?.profile}
      title="Условия использования"
      titleAs="h6"
      hiddenNavigate={false}
    >
      <Typography sx={{ mb: 2 }} as="h2" variant="h5">
        Не является публичной офертой
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Сайт предназначен для лиц, достигших 18-ти летнего возраста.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Вся представленная на сайте информация, касающаяся технических
        характеристик, наличия на складе, стоимости товаров, носит
        информационный характер и ни при каких условиях не является публичной
        офертой, определяемой положениями Статьи 437(2) Гражданского кодекса РФ.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Нажатие на кнопку "купить", а также последующее заполнение тех или иных
        форм, не накладывает на владельцев сайта никаких обязательств.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Присланное по e-mail сообщение или сообщение в телеграм бот
        @PinkiePieSecretShop_Bot, содержащее копию заполненной формы заявки на
        сайте, не является ответом на сообщение потребителя или подтверждением
        заказа со стороны владельцев сайта.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Все материалы, размещенные на сайте или телеграм боте
        @PinkiePieSecretShop_Bot являются собственностью владельцев сайта, либо
        собственностью организаций, с которыми у владельцев сайта есть
        соглащение о размещении материалов. Копирование любой информации может
        повлечь за собой ответственность.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Регистрируясь на сайте или телеграм боте @PinkiePieSecretShop_Bot или
        оставляя тем или иным способом свою персональную информацию, Вы
        делегируете право сотрудникам компании обрабатывать вашу персональную
        информацию.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Для аналитических целей на сайте работает система статистики, которая
        собирает информацию о посещенных страницах сайта, заполненных формах и
        тд. Сотрудники компании имеют доступ к этой информации.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Оформляя заказ на сайте или иным способом становясь клиентом нашей
        компании, вы принимаете условия{' '}
        <Link to={url.profileOfferta}>оферты</Link>.
      </Typography>
    </MainLayout>
  );
};
