import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetAddressData = async ({ jwt, query }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/dadata/get-address-data`,
    {
      query,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};
