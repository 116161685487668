import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
export const ProfilePage = () => {
  const navigate = useNavigate();

  return (
    <MainLayout activeNavigate={url?.profile} title="Профиль">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate('/profile/data')}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <PeopleAltOutlinedIcon />
              <Typography>Изменить мои данные</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate('/profile/order-history')}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <RestoreOutlinedIcon />
              <Typography>История заказов</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />

          <Typography as="p" variant="p" sx={{ mt: 4, mb: 2 }}>
            Информация
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate('/profile/support')}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <SupportAgentOutlinedIcon />
              <Typography>Поддержка</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate(url.profileReturnsRules)}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CurrencyExchangeOutlinedIcon />
              <Typography>Условия возврата, замены, гарантии</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate(url.policy)}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <SecurityOutlinedIcon />
              <Typography>Политика конфиденциальности</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate(url.profileOfferta)}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <DescriptionOutlinedIcon />
              <Typography>Договор оферты</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            spacing={2}
            sx={{ py: 2 }}
            onClick={() => navigate(url.profileUseRules)}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <TouchAppOutlinedIcon />
              <Typography>Условия использования</Typography>
            </Stack>
            <IconButton>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Stack>
          <Divider />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
