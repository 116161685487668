import React from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  AppBar,
  Box,
  Button,
  Container,
  Fab,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSelector } from 'react-redux';

import { url } from '../../utils/url';
import { AlertsList } from '../Default/AlertList/AlertList';
import { useCookies } from 'react-cookie';
import { useTelegram } from '../../hooks/useTelegram';

export const MainLayout = ({
  children,
  activeNavigate,
  title,
  titleAs = 'h6',
  hiddenNavigate = false,
  loading = false,
}) => {
  const navigate = useNavigate();
  const { openTelegramLink } = useTelegram();

  const alerts = useSelector((state) => state.alerts);
  const [cookie] = useCookies('jwt');
  return (
    <>
      {cookie?.jwt ? (
        <div style={{ background: '#f9f9f9', paddingBottom: '150px' }}>
          <Container maxWidth="xs">
            <AlertsList alerts={alerts} />
            {title && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ my: 3 }}
                  >
                    {!hiddenNavigate && (
                      <div>
                        <IconButton
                          color="primary"
                          aria-label="back"
                          size="small"
                          onClick={() => navigate(-1)}
                        >
                          <KeyboardBackspaceOutlinedIcon />
                        </IconButton>
                      </div>
                    )}
                    {!loading ? (
                      <Typography
                        as="h1"
                        variant={titleAs}
                        sx={{ lineHeight: '1' }}
                      >
                        {title}
                      </Typography>
                    ) : (
                      <Skeleton
                        variant="rounded"
                        sx={{ width: '100%', height: '50px' }}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            )}
            {children}

            <Typography
              sx={{
                color: '#b1b1b1',
                fontSize: '10px',
                textAlign: 'center',
                mt: 2,
              }}
            >
              Индивидуальный предприниматель Михеев Иван Михайлович. <br />
              ИНН: 519001127905.
            </Typography>
          </Container>
          <AppBar
            position="fixed"
            color="inherit"
            sx={{ top: 'auto', bottom: 0, pt: 0.5 }}
          >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack
                color="inherit"
                sx={{ opacity: 0.5 }}
                onClick={() => navigate(url?.home)}
              >
                <HomeOutlinedIcon
                  color={activeNavigate == url?.home ? 'primary' : 'inherit'}
                  sx={{ fontSize: '25px', mx: 'auto' }}
                />
                <Typography
                  sx={{ fontSize: '12px' }}
                  color={activeNavigate == url?.home ? 'primary' : 'inherit'}
                >
                  Главная
                </Typography>
              </Stack>
              <Stack
                color="inherit"
                sx={{ opacity: 0.5 }}
                onClick={() => navigate(url.shop)}
              >
                <GridViewOutlinedIcon
                  color={activeNavigate == url?.shop ? 'primary' : 'inherit'}
                  sx={{ fontSize: '25px', mx: 'auto' }}
                />
                <Typography
                  sx={{ fontSize: '12px' }}
                  color={activeNavigate == url?.shop ? 'primary' : 'inherit'}
                >
                  Каталог
                </Typography>
              </Stack>
              <Stack
                color="inherit"
                sx={{ opacity: 0.5 }}
                onClick={() => navigate(url.cart)}
              >
                <ShoppingCartOutlinedIcon
                  color={activeNavigate == url?.cart ? 'primary' : 'inherit'}
                  sx={{ fontSize: '25px', mx: 'auto' }}
                />
                <Typography
                  sx={{ fontSize: '12px' }}
                  color={activeNavigate == url?.cart ? 'primary' : 'inherit'}
                >
                  Корзина
                </Typography>
              </Stack>
              <Stack
                color="inherit"
                sx={{ opacity: 0.5 }}
                onClick={() => navigate(url.wishlist)}
              >
                <FavoriteBorderOutlinedIcon
                  color={
                    activeNavigate == url?.wishlist ? 'primary' : 'inherit'
                  }
                  sx={{ fontSize: '25px', mx: 'auto' }}
                />

                <Typography
                  sx={{ fontSize: '12px' }}
                  color={
                    activeNavigate == url?.wishlist ? 'primary' : 'inherit'
                  }
                >
                  Избранное
                </Typography>
              </Stack>

              <Stack
                color="inherit"
                sx={{ opacity: 0.5 }}
                onClick={() => navigate(url.profile)}
              >
                <AccountCircleOutlinedIcon
                  color={activeNavigate == url?.profile ? 'primary' : 'inherit'}
                  sx={{ fontSize: '25px', mx: 'auto' }}
                />

                <Typography
                  sx={{ fontSize: '12px' }}
                  color={activeNavigate == url?.profile ? 'primary' : 'inherit'}
                >
                  Профиль
                </Typography>
              </Stack>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ maxWidth: '400px', p: 3, textAlign: 'center' }}>
            <Typography variant="h1">🦹‍♀️</Typography>
            <Typography variant="h6">Мы вас не узнали!</Typography>
            <Typography>
              Пожалуйста передите в телеграм и снова нажите кнопку "старт" или
              введите команду /start
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                openTelegramLink(
                  'https://t.me/PinkiePieSecretShop_Bot?start=start',
                )
              }
              sx={{ mt: 3 }}
            >
              Перейти в Telegram
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
