import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { url } from './utils/url';
import { HomePage } from './pages/HomePage';
import { ProductCategoryPage } from './pages/ProductCategoryPage';
import { ProductPage } from './pages/ProductPage';
import { CartPage } from './pages/CartPage';
import { OrderSelectDeliveryPage } from './pages/OrderSelectDeliveryPage';
import { OrderCdekDeliveryPage } from './pages/OrderCdekDeliveryPage';
import { OrderCustomerPage } from './pages/OrderCustomerPage';
import { OrderFinalPage } from './pages/OrderFinalPage';
import { CatalogPage } from './pages/CatalogPage';
import { WishlistPage } from './pages/WishlistPage';
import { ProfilePage } from './pages/ProfilePage';
import { ProfileDataPage } from './pages/ProfileDataPage';
import { ProfileOrderHistoryPage } from './pages/ProfileOrderHistoryPage';
import { OrderCourierDeliveryPage } from './pages/OrderCourierDeliveryPage';
import { OrderResultPage } from './pages/OrderResultPage';
import { ProfileSupportPage } from './pages/ProfileSupportPage';
import { PolicyPage } from './pages/PolicyPage';
import { useEffect } from 'react';
import eruda from 'eruda';
import { useTelegram } from './hooks/useTelegram';
import { OffertaPage } from './pages/OffertaPage';
import { ReturnsPage } from './pages/ReturnsPage';
import { UseRulesPage } from './pages/UseRulesPage';
import { OrderProductsPage } from './pages/OrderProductsPage';
import { ErrorPage } from './pages/ErrorPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d15edc',
      light: '#ea78f5',
      dark: '#a64eaf',
      contrastText: '#fff',
    },
    secondary: {
      main: '#303E9F12',
      light: '#4b5ac612',
      dark: '#29358712',
      contrastText: '#303E9F',
    },
    error: {
      main: '#fa546f',
      light: '#f78396',
      dark: '#ea5069',
      contrastText: '#fff',
    },
    warning: {
      main: '#ffd03c',
      light: '#ffd961',
      dark: '#f0bc18',
      contrastText: '#fff',
    },
    success: {
      main: '#39a473',
      light: '#76eab5',
      dark: '#24905f',
      contrastText: '#fff',
    },
  },
});

function App() {
  const { init } = useTelegram();
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV == 'development') {
      eruda.init();
    }

    init();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path={url.home} element={<HomePage />} />
          <Route exact path={url.category} element={<ProductCategoryPage />} />
          <Route exact path={url.productVariation} element={<ProductPage />} />
          <Route exact path={url.cart} element={<CartPage />} />
          <Route
            exact
            path={url.orderDelivery}
            element={<OrderSelectDeliveryPage />}
          />
          <Route
            exact
            path={url.orderDeliveryCdek}
            element={<OrderCdekDeliveryPage />}
          />
          <Route
            exact
            path={url.orderDeliveryCourier}
            element={<OrderCourierDeliveryPage />}
          />
          <Route
            exact
            path={url.orderCustomer}
            element={<OrderCustomerPage />}
          />
          <Route exact path={url.orderFinal} element={<OrderFinalPage />} />
          <Route exact path={url.orderResult} element={<OrderResultPage />} />
          <Route
            exact
            path={url.orderProducts}
            element={<OrderProductsPage />}
          />
          <Route exact path={url.shop} element={<CatalogPage />} />
          <Route exact path={url.search} element={<ProductCategoryPage />} />
          <Route exact path={url.wishlist} element={<WishlistPage />} />
          <Route exact path={url.profile} element={<ProfilePage />} />
          <Route exact path={url.profileData} element={<ProfileDataPage />} />
          <Route exact path={url.policy} element={<PolicyPage />} />
          <Route exact path={url.profileOfferta} element={<OffertaPage />} />
          <Route exact path={url.profileUseRules} element={<UseRulesPage />} />
          <Route
            exact
            path={url.profileReturnsRules}
            element={<ReturnsPage />}
          />
          <Route
            exact
            path={url.profileSupport}
            element={<ProfileSupportPage />}
          />
          <Route
            exact
            path={url.profileOrders}
            element={<ProfileOrderHistoryPage />}
          />
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
