import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { EditProductCount } from '../../api/OrderApi';
import { useCookies } from 'react-cookie';

export const OrderItem = ({
  product,
  orderId,
  UpdateProductListHandler,
  showCount = true,
}) => {
  const EditProductCountApi = useApi(EditProductCount);
  const [cookie] = useCookies('jwt');
  const [productCount, setProductCount] = useState(product?.count || 0);

  const EditProductCountHandler = async (count) => {
    const result = await EditProductCountApi.sendRequest({
      jwt: cookie.jwt,
      orderProductId: product.id,
      variationId: product?.product?.variations[0]?.id,
      count,
    });
    UpdateProductListHandler();
  };

  return (
    <>
      {productCount > 0 && (
        <Box sx={{ position: 'relative' }}>
          <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
            <Box>
              <Card
                sx={{
                  width: '60px',
                  maxWidth: '60px',
                  height: '60px',

                  overflow: 'hidden',
                  borderRadius: '7px',
                }}
              >
                <img
                  alt={product?.name}
                  src={product?.image || '/not-found-image.jpeg'}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Card>
            </Box>
            <Box>
              <Typography
                as="p"
                sx={{ lineHeight: '1.2', color: '#3d3d3d', fontSize: '14px' }}
              >
                {product?.name} (
                {product.color && product.size
                  ? product.color + ' - ' + product.size
                  : product.color
                  ? product.color
                  : product?.size && product?.size}
                )
              </Typography>
              <Typography as="p" color="primary">
                {product?.finalPrice * productCount} руб.
              </Typography>
            </Box>
            {showCount && (
              <Box sx={{ width: '150px', flexGrow: 1 }}>
                <Stack direction="row" spacing="1" alignItems="center">
                  <Box>
                    <IconButton
                      disabled={productCount == 0}
                      onClick={() => {
                        const count = productCount > 0 ? productCount - 1 : 0;
                        setProductCount(count);
                        EditProductCountHandler(count);
                      }}
                    >
                      -
                    </IconButton>
                  </Box>

                  <Box>
                    <Typography as="p" variant="caption">
                      {productCount} шт.
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      disabled={
                        productCount >= product?.product?.variantion?.length
                          ? product?.product?.variantion[0]?.stock
                          : 0
                      }
                      onClick={() => {
                        const count = productCount + 1;
                        setProductCount(count);
                        EditProductCountHandler(count);
                      }}
                    >
                      +
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
