import React, { useEffect } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { useOrder } from '../hooks/useOrder';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../utils/url';
import { OrderStepper } from '../components/Order/OrderStepper';
import { Box, Button, Grid, Typography } from '@mui/material';
import { OrderItem } from '../components/Order/OrderItem';
import { OrderFooter } from '../components/Order/OrderFooter';

export const OrderProductsPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { order, GetOrderByIdHandler, UpdateOrderHandler, GoToOrderStep } =
    useOrder({});

  useEffect(() => {
    if (orderId) {
      GetOrderByIdHandler({ orderId });
    }
  }, [orderId]);
  return (
    <MainLayout
      activeNavigate={url?.cart}
      title="Оформление заказа"
      titleAs="h6"
      hiddenNavigate={true}
    >
      <OrderStepper orderId={orderId} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {order?.products?.length ? (
            order?.products
              ?.filter((p) => p.count > 0)
              ?.map((product) => (
                <OrderItem
                  product={product}
                  OrderItem={() => GetOrderByIdHandler({ orderId })}
                />
              ))
          ) : (
            <Box sx={{ textAlign: 'center', mb: 5 }}>
              <Typography>Вы еще не добавили товары в заказ</Typography>
              <Button
                sx={{ mt: 3 }}
                color="primary"
                variant="contained"
                onClick={() => navigate(url.shop)}
              >
                Перейти в каталог
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      {!!order?.products?.length && (
        <OrderFooter
          nextButtonText="Продолжить"
          loading={false}
          showBackButton={false}
          disabled={!order?.products?.length}
          nextButtonHandler={async () => {
            navigate(`/order/${order?.id}/delivery`);
          }}
        />
      )}
    </MainLayout>
  );
};
