import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const RemoveAnimationPlaceholder = ({ startAnimation }) => {
  const [style, setStyle] = useState({ width: 0 });
  useEffect(() => {
    if (startAnimation) {
      setStyle({
        width: 'calc(100% + 30px)',
        transition: '0.5s',
        animationTimingFunction: 'linear',
      });
    }
  }, [startAnimation]);
  return (
    <Box
      sx={{
        left: '-15px',
        right: '-30px',
        top: '-15px',
        position: 'absolute',
        height: 'calc(100% + 15px)',

        background: 'rgb(255 65 65 / 10%)',
        zIndex: 1,
        ...style,
      }}
    ></Box>
  );
};
