import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { GetOrderById, GetOrderPayment } from '../api/OrderApi';
import { useCookies } from 'react-cookie';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const OrderResultPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const [payment, setPayment] = useState({});

  const GetOrderByIdApi = useApi(GetOrderById);
  const GetOrderPaymentApi = useApi(GetOrderPayment);
  const [cookie] = useCookies('jwt');
  const navigate = useNavigate();
  const getOrderInfoByIdHandler = async () => {
    const result = await GetOrderByIdApi.sendRequest({
      jwt: cookie.jwt,
      orderId,
    });
    setOrder(result);
  };

  const getOrderPaymentInfoHandler = async () => {
    const result = await GetOrderPaymentApi.sendRequest({
      jwt: cookie.jwt,
      orderId,
    });
    setPayment(result);
  };

  useEffect(() => {
    getOrderInfoByIdHandler();
  }, []);

  useEffect(() => {
    if (order?.payment) {
      getOrderPaymentInfoHandler();
    }
  }, [order]);

  return (
    <MainLayout
      activeNavigate={url?.cart}
      title=""
      titleAs="h6"
      hiddenNavigate={true}
    >
      {!!Object.keys(payment)?.length ? (
        <Stack alignItems="center" sx={{ mt: 20 }}>
          {['CONFIRMED', 'AUTHORIZED'].indexOf(payment?.Status) !== -1 ? (
            <Box sx={{ width: '250px', height: '250px' }}>
              <img
                src="/success.png"
                style={{ objectFit: 'cover', width: '100%', height: ' 100%' }}
              />
            </Box>
          ) : [
              'NEW',
              'FORM_SHOWED',
              'AUTHORIZING',
              'CONFIRMING',
              'REFUNDING',
              'ASYNC_REFUNDING',
            ].indexOf(payment?.Status) !== -1 ? (
            <Box sx={{ width: '250px', height: '250px' }}>
              <img
                src="/waiting.png"
                style={{ objectFit: 'cover', width: '100%', height: ' 100%' }}
              />
            </Box>
          ) : (
            ['PARTIAL_REFUNDED', 'REFUNDED', 'CANCELED', 'REJECTED'].indexOf(
              payment?.Status,
            ) !== -1 ?? (
              <Box sx={{ width: '250px', height: '250px' }}>
                <img
                  src="/error.png"
                  style={{ objectFit: 'cover', width: '100%', height: ' 100%' }}
                />
              </Box>
            )
          )}

          <Typography as="h1" variant="h6" textAlign="center">
            {['CONFIRMED', 'AUTHORIZED'].indexOf(payment?.Status) !== -1
              ? 'Заказ успешно создан!'
              : [
                  'NEW',
                  'FORM_SHOWED',

                  'AUTHORIZING',
                  'CONFIRMING',
                  'REFUNDING',
                  'ASYNC_REFUNDING',
                ].indexOf(payment?.Status) !== -1
              ? 'Заказ ожидает оплаты'
              : [
                  'PARTIAL_REFUNDED',
                  'REFUNDED',
                  'CANCELED',
                  'REJECTED',
                ].indexOf(payment?.Status) !== -1 ??
                'Ошибка при создании заказа'}
          </Typography>

          <Typography as="p" variant="p" textAlign="center">
            {['CONFIRMED', 'AUTHORIZED'].indexOf(payment?.Status) !== -1
              ? 'Заказ отправлен на склад. В билжайшее время с вами свяжется наш менеджер для уточнения деталей доставки.'
              : [
                  'NEW',
                  'FORM_SHOWED',

                  'AUTHORIZING',
                  'CONFIRMING',
                  'REFUNDING',
                  'ASYNC_REFUNDING',
                ].indexOf(payment?.Status) !== -1
              ? 'Ваш заказ создан. Оплатите заказ в течение 15 минут. Если заказ не будет оплачен в течение 15 минут, система автоматически удалит его.'
              : [
                  'PARTIAL_REFUNDED',
                  'REFUNDED',
                  'CANCELED',
                  'REJECTED',
                ].indexOf(payment?.Status) !== -1 ??
                `Произошла ошибка при оформлении заказа. Возможно товар закончился на складе или произошла ошибка при оплате. Вы можете повторить заказ снова`}
          </Typography>

          {['CONFIRMED', 'AUTHORIZED'].indexOf(payment?.Status) !== -1 ? (
            <LoadingButton
              loading={GetOrderByIdApi?.loading}
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() => navigate(url.shop)}
            >
              Вернуться к покупкам
            </LoadingButton>
          ) : [
              'NEW',
              'FORM_SHOWED',

              'AUTHORIZING',
              'CONFIRMING',
              'REFUNDING',
              'ASYNC_REFUNDING',
            ].indexOf(payment?.Status) !== -1 ? (
            <LoadingButton
              loading={GetOrderByIdApi?.loading}
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() => (window.location = order?.payment?.paymentURL)}
            >
              Перейти к оплате
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={GetOrderByIdApi?.loading}
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() => navigate(url.shop)}
            >
              Вернуться к покупкам
            </LoadingButton>
          )}
        </Stack>
      ) : (
        <Stack alignItems="center" sx={{ mt: 20 }}>
          <Box sx={{ width: '250px', height: '250px' }}>
            <img
              src="/success.png"
              style={{ objectFit: 'cover', width: '100%', height: ' 100%' }}
            />
          </Box>

          <Typography as="h1" variant="h6" textAlign="center">
            Заказ успешно создан!
          </Typography>

          <Typography as="p" variant="p" textAlign="center">
            Заказ отправлен на склад. В билжайшее время с вами свяжется наш
            менеджер для уточнения деталей доставки.
          </Typography>

          <LoadingButton
            loading={GetOrderByIdApi?.loading}
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={() => navigate(url.shop)}
          >
            Вернуться к покупкам
          </LoadingButton>
        </Stack>
      )}
    </MainLayout>
  );
};
