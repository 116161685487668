import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { useCookies } from 'react-cookie';
import { useApi } from '../../hooks/useApi';
import {
  CreateWishlistFromTelegram,
  RemoveWishlistFromTelegram,
} from '../../api/WishlistApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';

export const WishlistButton = ({
  product,
  customer,
  size = 'small',
  removeItemFromList = () => {},
}) => {
  const [inWish, setInWish] = useState(false);
  const CreateWishlistApi = useApi(CreateWishlistFromTelegram);
  const RemoveWishlistApi = useApi(RemoveWishlistFromTelegram);
  const [cookie] = useCookies('jwt');

  useEffect(() => {
    if (product?.inWishList) {
      setInWish(product?.inWishList);
    }
  }, [product]);
  const dispatch = useDispatch();
  const wishlistHandler = async (product) => {
    if (inWish) {
      await RemoveWishlistApi.sendRequest({
        productId: product.id,
        jwt: cookie.jwt,
      });
      dispatch(setAlert({ status: 200, text: 'Товар удален из избранного' }));
      setInWish(false);
      removeItemFromList();
    } else {
      await CreateWishlistApi.sendRequest({
        jwt: cookie.jwt,
        customer,
        product,
      });

      dispatch(setAlert({ status: 200, text: 'Товар добавлен в избранное' }));
      setInWish(true);
    }
  };
  return (
    <IconButton
      aria-label="wishlist"
      size={size}
      onClick={() => wishlistHandler(product)}
      disabled={CreateWishlistApi.loading || RemoveWishlistApi.loading}
    >
      {inWish ? (
        <FavoriteOutlinedIcon
          sx={{ fontSize: size == 'large' ? '30px' : '20px' }}
        />
      ) : (
        <FavoriteBorderOutlinedIcon
          sx={{ fontSize: size == 'large' ? '30px' : '20px' }}
        />
      )}
    </IconButton>
  );
};
