import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useApi } from '../../hooks/useApi';
import { useQuery } from '../../hooks/useQuery';
import { GetFilterParams, ProductFilter } from '../../api/ProductApi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrderFooter } from '../Order/OrderFooter';
import querystring from 'querystring';
import { useNavigate, useLocation } from 'react-router-dom';

export const Search = ({ limit, offset, defaultFilter, category }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterParams, setFilterParams] = useState(false);
  const [filter, setFilter] = useState({});
  const GetFilterParamsApi = useApi(GetFilterParams);

  const [filterFullCount, setFilterFullCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (defaultFilter) {
      setFilter({
        ...defaultFilter,
        limit,
        offset,
        categorySlug: category.slug,
      });
    }
  }, [defaultFilter]);

  useMemo(async () => {
    const fp = await GetFilterParamsApi.sendRequest();
    setFilterParams(fp);
  }, [location]);
  const openFilterHandler = async () => {
    setOpenFilter(true);
  };

  useEffect(() => {
    setFilterFullCount(
      (filter?.pack?.length ||
        0 + filter?.material?.length ||
        0 + filter?.collectionName?.length ||
        0 + filter?.batteries?.length ||
        0) +
        (filter?.priceMax || filter?.priceMin ? 1 : 0) +
        (filter?.paramterLengthMax || filter?.paramterLengthMin ? 1 : 0) +
        (filter?.paramterBruttoMax || filter?.paramterBruttoMin ? 1 : 0) +
        (filter?.paramterDiameterMax || filter?.paramterDiameterMin ? 1 : 0),
    );
  }, [filter]);

  const filterHandler = () => {
    setOpenFilter(false);
    if (filter?.categorySlug) {
      navigate(
        `/shop/${filter?.categorySlug}?${querystring.stringify(filter)}`,
      );
    } else {
      navigate(`/search?${querystring.stringify(filter)}`);
    }
  };
  return (
    <>
      <Grid container spacing={1} sx={{ my: 2 }} alignItems="center">
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              id="search-field"
              label="Поиск"
              variant="standard"
              fullWidth
              value={filter?.search || ''}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value } }) =>
                setFilter({ ...filter, search: value })
              }
            />
            <Box sx={{ width: '50px' }}>
              <IconButton onClick={() => filterHandler()}>
                <SearchOutlinedIcon />
              </IconButton>
            </Box>
            {/* <Box sx={{ width: '50px' }}>
              <IconButton onClick={() => openFilterHandler()}>
                <TuneOutlinedIcon />
              </IconButton>
            </Box> */}
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}></Box>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <Box sx={{ p: 2, minWidth: '300px', pb: 15 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Typography
              sx={{ fontWeight: 'bold', position: 'relative' }}
              as="p"
              variant="h5"
            >
              Фильтр
              {!!filterFullCount && (
                <Chip
                  color="error"
                  sx={{ position: 'absolute', zIndex: 1 }}
                  size="small"
                  label={filterFullCount}
                />
              )}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  setFilter({});
                  if (category?.id) {
                    navigate(`/shop/${category.slug}`);
                  } else {
                    navigate(`/shop`);
                  }
                }}
              >
                Сбросить все
              </Button>
              <IconButton
                sx={{ mt: 1, mx: 2 }}
                onClick={() => {
                  setOpenFilter(false);
                }}
              >
                <ClearOutlinedIcon />
              </IconButton>
            </Stack>
          </Stack>
          <TextField
            id="search-field"
            label="Поиск"
            variant="outlined"
            fullWidth
            value={filter?.search || ''}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 3 }}
            onChange={({ target: { value } }) =>
              setFilter({ ...filter, search: value })
            }
          />
          <Stack direction="row" spacing={2} sx={{ mb: 3, width: '100%' }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!+filter?.superSale}
                    onChange={({ target: { checked } }) => {
                      setFilter({ ...filter, superSale: checked ? 1 : 0 });
                    }}
                  />
                }
                label="Со скидкой"
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!+filter?.inStock}
                    onChange={({ target: { checked } }) => {
                      setFilter({ ...filter, inStock: checked ? 1 : 0 });
                    }}
                  />
                }
                label="В наличии"
              />
            </Box>
          </Stack>
          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="category-price"
              id="category-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Категория
                {!!filter?.categorySlug && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.categorySlug && (
                <Button
                  sx={{ ml: 'auto', mr: 2 }}
                  onClick={() => setFilter({ ...filter, categorySlug: '' })}
                >
                  Сброс
                </Button>
              )}
            </AccordionSummary>

            <AccordionDetails sx={{ p: 0 }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={filter?.categorySlug}
                onChange={({ target: { value } }) => {
                  setFilter({ ...filter, categorySlug: value });
                }}
              >
                {filterParams?.categories?.map((c) => (
                  <FormControlLabel
                    key={c.id}
                    value={c.slug}
                    control={<Radio />}
                    label={c.name}
                  />
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Цена
                {(!!filter?.priceMin || !!filter?.priceMax) && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {(!!filter?.priceMin || !!filter?.priceMax) && (
                <Button
                  sx={{ ml: 'auto', mr: 2 }}
                  onClick={() =>
                    setFilter({ ...filter, priceMin: '', priceMax: '' })
                  }
                >
                  Сброс
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label={`от ${filterParams?.priceMin || 0} руб`}
                  value={filter?.priceMin}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, priceMin: value })
                  }
                />
                <TextField
                  label={`до ${filterParams?.priceMax || 0} руб`}
                  value={filter?.priceMax}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, priceMax: value })
                  }
                />
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Длина
                {(!!filter?.paramterLengthMin ||
                  !!filter?.paramterLengthMax) && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {(!!filter?.paramterLengthMin || !!filter?.paramterLengthMax) && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label={`от ${filterParams?.paramterLengthMin || 0} см.`}
                  value={filter?.paramterLengthMin}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, paramterLengthMin: value })
                  }
                />
                <TextField
                  label={`до ${filterParams?.paramterLengthMax || 0} см.`}
                  value={filter?.paramterLengthMax}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, paramterLengthMax: value })
                  }
                />
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Вес
                {(!!filter?.paramterBruttoMin ||
                  !!filter?.paramterBruttoMax) && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {(!!filter?.paramterBruttoMin || !!filter?.paramterBruttoMax) && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label={`от ${filterParams?.paramterBruttoMin || 0} гр.`}
                  value={filter?.paramterBruttoMin}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, paramterBruttoMin: value })
                  }
                />
                <TextField
                  label={`до ${filterParams?.paramterBruttoMax || 0} гр.`}
                  value={filter?.paramterBruttoMax}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, paramterBruttoMax: value })
                  }
                />
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Диаметр
                {(!!filter?.paramterDiameterMin ||
                  filter?.paramterDiameterMax) && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {(!!filter?.paramterDiameterMin ||
                filter?.paramterDiameterMax) && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label={`от ${filterParams?.paramterDiameterMin || 0} см.`}
                  value={filter?.paramterDiameterMin}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, paramterDiameterMin: value })
                  }
                />
                <TextField
                  label={`до ${filterParams?.paramterDiameterMax || 0} см.`}
                  value={filter?.paramterDiameterMax}
                  onChange={({ target: { value } }) =>
                    setFilter({ ...filter, paramterDiameterMax: value })
                  }
                />
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Цвет
                {!!filter?.color?.length && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.color?.length && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack>
                {filterParams?.color?.map((color, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={!!filter?.color?.some((cn) => cn == color)}
                        onChange={({ target: { checked } }) => {
                          let cnArray = filter?.color || [];
                          if (checked) {
                            cnArray.push(color);
                          } else {
                            cnArray = cnArray?.filter((cn) => cn !== color);
                          }
                          setFilter({ ...filter, color: cnArray });
                        }}
                      />
                    }
                    label={color}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Размер
                {!!filter?.size?.length && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.size?.length && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack>
                {filterParams?.size?.map((size, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={!!filter?.size?.some((cn) => cn == size)}
                        onChange={({ target: { checked } }) => {
                          let cnArray = filter?.size || [];
                          if (checked) {
                            cnArray.push(size);
                          } else {
                            cnArray = cnArray?.filter((cn) => cn !== size);
                          }
                          setFilter({ ...filter, size: cnArray });
                        }}
                      />
                    }
                    label={size}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Коллекция
                {!!filter?.collectionName?.length && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.collectionName?.length && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack>
                {filterParams?.collectionName?.map((collectionName, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={
                          !!filter?.collectionName?.some(
                            (cn) => cn == collectionName,
                          )
                        }
                        onChange={({ target: { checked } }) => {
                          let cnArray = filter?.collectionName || [];
                          if (checked) {
                            cnArray.push(collectionName);
                          } else {
                            cnArray = cnArray?.filter(
                              (cn) => cn !== collectionName,
                            );
                          }
                          setFilter({ ...filter, collectionName: cnArray });
                        }}
                      />
                    }
                    label={collectionName}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Упаковка
                {!!filter?.pack?.length && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.pack?.length && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack>
                {filterParams?.pack?.map((pack, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={!!filter?.pack?.some((cn) => cn == pack)}
                        onChange={({ target: { checked } }) => {
                          let cnArray = filter?.pack || [];
                          if (checked) {
                            cnArray.push(pack);
                          } else {
                            cnArray = cnArray?.filter((cn) => cn !== pack);
                          }
                          setFilter({ ...filter, pack: cnArray });
                        }}
                      />
                    }
                    label={pack}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Батарейки
                {!!filter?.batteries?.length && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.batteries?.length && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack>
                {filterParams?.batteries?.map((battery, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={
                          !!filter?.batteries?.some((cn) => cn == battery)
                        }
                        onChange={({ target: { checked } }) => {
                          let cnArray = filter?.batteries || [];
                          if (checked) {
                            cnArray.push(battery);
                          } else {
                            cnArray = cnArray?.filter((cn) => cn !== battery);
                          }
                          setFilter({ ...filter, batteries: cnArray });
                        }}
                      />
                    }
                    label={battery}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              sx={{ p: 0, boxShadow: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-price"
              id="filter-price"
            >
              <Typography
                sx={{ fontWeight: 'bold', width: '150px', fontSize: '20px' }}
                as="p"
              >
                Материал
                {!!filter?.material?.length && (
                  <Chip
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      p: 0,
                      width: '10px',
                      height: '10px',
                    }}
                    size="small"
                  />
                )}
              </Typography>
              {!!filter?.material?.length && (
                <Button sx={{ ml: 'auto', mr: 2 }}>Сброс</Button>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack>
                {filterParams?.material?.map((material, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={
                          !!filter?.material?.some((cn) => cn == material)
                        }
                        onChange={({ target: { checked } }) => {
                          let cnArray = filter?.material || [];
                          if (checked) {
                            cnArray.push(material);
                          } else {
                            cnArray = cnArray?.filter((cn) => cn !== material);
                          }
                          setFilter({ ...filter, material: cnArray });
                        }}
                      />
                    }
                    label={material}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <OrderFooter
          fullBottom={true}
          nextButtonText="Отфильтровать"
          nextButtonHandler={() => {
            filterHandler();
          }}
        />
      </Drawer>
    </>
  );
};
