import { Box, Card, Fab, Modal, Stack } from '@mui/material';
import React from 'react';

import { SwiperSlide, Swiper } from 'swiper/react';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
export const ProductImagePopup = ({ open, close, index, images }) => {
  return (
    <Modal
      open={open}
      onClose={() => close(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ width: '340px' }}>
        <Swiper
          effect={'cards'}
          grabCursor={true}
          loop={true}
          modules={[EffectCards]}
          className="mySwiper"
          initialSlide={index}
        >
          {images?.map((picture) => (
            <SwiperSlide style={{ borderRadius: '20px' }}>
              <Card
                sx={{
                  mt: 2,
                  borderRadius: '20px',
                  border: 'none',
                  mx: 0.5,
                  width: '340px',
                  height: '450px',
                }}
                variant="outlined"
              >
                <img
                  src={picture?.url}
                  style={{
                    width: '100%',
                    borderRadius: '20px',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <Stack sx={{ width: '100%' }} alignItems="center">
          <Box>
            <Fab onClick={() => close(false)} sx={{ mt: 1 }} color="error">
              <ClearOutlinedIcon />
            </Fab>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
