import axios from 'axios';
import { BACKEND_URL } from '../utils/url';
export const CreateOrFindCustomerAndJWTAuth = async ({
  chatId = null,
  username = null,
}) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/customer/create-from-telegram`,
    {
      meta: [{ key: 'TelegramChatId', value: chatId }],
      nickname: username,
    },
  );
  return data;
};

export const GetMeFromTelegram = async ({ jwt }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/customer/get-me-from-telegram`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};

export const EditMe = async ({ jwt, body }) => {
  const { data } = await axios.patch(
    `${BACKEND_URL}/v1/customer/edit-me`,
    body,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return data;
};
