import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WishlistButton } from '../Wishlist/WishlistButton';
import { CartButton } from '../Cart/CartButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

export const ProductItem = ({
  product,
  category,
  customer,
  updateStockHandler,
  removeItemFromList = () => {},
}) => {
  const navigate = useNavigate();
  const [cartItem, setCartItem] = useState({
    name: product?.name,
    image: product?.pictures[0]?.url,
    retailPrice: product?.price?.retailPrice,
    wholePrice: product?.price?.wholePrice,
    discount: product?.price?.discount,
    count:
      customer?.cart?.find(
        (c) => c.upsertUniqKey == customer?.id + '_' + product?.id,
      )?.count || 1,
    aIDs: product?.variations
      ?.map((v) => ({
        variationId: v.id,
        aID: v.aID,
        color: v.color,
        size: v.size,
        stock: v.stock,
      }))
      .sort((a, b) => (a.stock >= b.stock ? -1 : 1)),

    prodID: product?.prodID,

    product: {
      id: product?.id,
    },
    productURL:
      window?.location?.pathname +
      '/' +
      product?.slug +
      '/' +
      product.variations[0]?.aID,
  });
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '180px',
          background: '#fff',
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0 0 15px rgba(0,0,0,0.15)',
          mb: 2,
        }}
        onClick={() =>
          navigate(
            `/shop/${category?.slug}/${product?.slug}/${product?.variations[0]?.id}`,
          )
        }
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            position: 'absolute',
            zIndex: 999,
            left: 7,
            top: 7,
          }}
        >
          {product?.price?.retailPrice < product?.price?.baseRetailPrice && (
            <Chip
              color="secondary"
              size="small"
              variant="filled"
              label={`-${product?.price?.discount}%`}
            />
          )}
          {product?.variations?.reduce(
            (a, b) => (+a.stock || 0) + (+b.stock || 0),
            0,
          ) == 0 && <Chip size="small" label="Нет в наличии" color="error" />}
        </Stack>

        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          modules={[Pagination]}
          pagination={true}
          className="mySwiper"
        >
          {product?.pictures?.slice(0, 5)?.map((picture) => (
            <SwiperSlide
              key={picture?.id}
              style={{
                overflow: 'hidden',
                background: 'none',
                textAlign: 'left',
              }}
            >
              <img
                src={picture?.url}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Typography sx={{ mb: 0, mt: 1, fontWeight: 'bold', fontSize: '14px' }}>
        {product?.name?.length > 35
          ? product?.name?.slice(0, 35) + '...'
          : product?.name}
      </Typography>
      <Typography
        variant="caption"
        sx={{ mb: 0, mt: 1, color: '#dd00e1', fontSize: '14px' }}
      >
        {product?.price && product?.price?.retailPrice} руб.
      </Typography>
      {product?.price?.retailPrice < product?.price?.baseRetailPrice && (
        <Typography
          variant="caption"
          sx={{ mb: 0, ml: 1, mt: 1, fontSize: '14px', color: '#818181' }}
        >
          <s>{product?.price && product?.price?.baseRetailPrice} руб.</s>
        </Typography>
      )}

      <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 2 }}>
        <CartButton
          cartItem={cartItem}
          disabled={!product?.variations.filter((v) => v.stock > 0)?.length}
          setCartItem={(data) => setCartItem(data)}
          updateStockHandler={() => updateStockHandler()}
          smallItemButton={true}
        />
        <WishlistButton
          product={product}
          customer={customer}
          removeItemFromList={removeItemFromList}
        />
      </Stack>
    </Box>
  );
};
