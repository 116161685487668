import { Box, Fab, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { useMe } from '../hooks/useMe';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MaleOutlinedIcon from '@mui/icons-material/MaleOutlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import { OrderFooter } from '../components/Order/OrderFooter';
import { useApi } from '../hooks/useApi';
import { EditMe } from '../api/CustomerApi';
import { useCookies } from 'react-cookie';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import isEmail from 'validator/lib/isEmail';
import { isMobilePhone } from 'validator';

export const ProfileDataPage = () => {
  const { customer } = useMe();
  const [customerData, setCustomerData] = useState();
  const EditMeApi = useApi(EditMe);
  const [cookie] = useCookies('jwt');
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    if (customer) {
      setCustomerData(customer);
    }

    if (Object.keys(customer)?.length) {
      if (customer?.email) {
        if (!isEmail(customer?.email)) {
          setError({ ...error, email: true });
        } else {
          setError({ ...error, email: false });
        }
      }
    }
  }, [customer]);
  const dispatch = useDispatch();

  return (
    <MainLayout activeNavigate={url?.profile} title="Изменить мои данные">
      <Grid
        container
        spacing={2}
        sx={{
          height: focus ? `calc(${window?.screen?.height}px)` : 'auto',
        }}
      >
        <Grid item xs={12}>
          <Stack sx={{ mb: 2 }} direction="row" spacing={2}>
            <TextField
              label="Ваше имя"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={customerData?.firstName}
              onChange={({ target: { value } }) =>
                setCustomerData({ ...customerData, firstName: value })
              }
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
            <TextField
              label="Ваша фамилия"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={customerData?.lastName}
              onChange={({ target: { value } }) =>
                setCustomerData({ ...customerData, lastName: value })
              }
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </Stack>
          <Stack sx={{ mb: 2 }}>
            <TextField
              label="Ваш email"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={customerData?.email}
              onChange={({ target: { value } }) =>
                setCustomerData({ ...customerData, email: value })
              }
              error={error?.email}
              helperText={
                error?.phone && 'Поле должно содержать реальный email телефона'
              }
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </Stack>
          <Stack sx={{ mb: 2 }}>
            <InputMask
              label="Ваш номер телефона"
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={customerData?.phone}
              onChange={({ target: { value } }) =>
                setCustomerData({ ...customerData, phone: value })
              }
              mask="+7(999)999-99-99"
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            >
              {(props) => <TextField {...props} />}
            </InputMask>
          </Stack>
          <Stack sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Дата рождения"
                value={customerData?.bithDate}
                onChange={(newValue) =>
                  setCustomerData({ ...customerData, bithDate: newValue })
                }
              />
            </LocalizationProvider>
          </Stack>
          <Stack>
            <Typography as="p" variant="caption">
              Пол
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Box>
                <Fab
                  variant="outline"
                  color={customerData?.sex == 'MALE' ? 'primary' : 'secondary'}
                  onClick={() =>
                    setCustomerData({ ...customerData, sex: 'MALE' })
                  }
                >
                  <MaleOutlinedIcon />
                </Fab>
              </Box>
              <Box>
                <Fab
                  variant="outline"
                  color={
                    customerData?.sex == 'FEMALE' ? 'primary' : 'secondary'
                  }
                  onClick={() =>
                    setCustomerData({ ...customerData, sex: 'FEMALE' })
                  }
                >
                  <FemaleOutlinedIcon />
                </Fab>
              </Box>
              <Box>
                <Fab
                  variant="outline"
                  color={
                    customerData?.sex == 'TRANSGENDER' ? 'primary' : 'secondary'
                  }
                  onClick={() =>
                    setCustomerData({ ...customerData, sex: 'TRANSGENDER' })
                  }
                >
                  <TransgenderOutlinedIcon />
                </Fab>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <OrderFooter
        nextButtonText="Сохранить"
        loading={EditMeApi.loading}
        showBackButton={false}
        nextButtonHandler={async () => {
          await EditMeApi.sendRequest({
            jwt: cookie.jwt,
            body: customerData,
          });
          dispatch(setAlert({ status: 200, text: 'Профиль обновлен' }));
        }}
      />
    </MainLayout>
  );
};
