import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useApi } from '../../hooks/useApi';
import { GetAddressData } from '../../api/DadataApi';
import { GetCdekCalculatorTariff, GetCdekLocation } from '../../api/CdekApi';

export const CdekDeliveryToHome = ({
  selectAddressHandler,
  deliveryVariant,
  order,
  focus,
  setFocus,
}) => {
  const [homeAddress, setHomeAddress] = useState({
    flat: '',
    city: '',
    street: '',
    zip: '',
    house: '',
  });

  const [dadataAddresses, setDadataAddresses] = useState([]);
  const [openHomeAddressDrawer, setOpenHomeAddressDrawer] = useState(false);

  const [cookie] = useCookies('jwt');
  const GetAddressDataApi = useApi(GetAddressData);
  const GetCdekLocationApi = useApi(GetCdekLocation);
  const GetCdekCalculatorTariffApi = useApi(GetCdekCalculatorTariff);
  const changeAddress = async (value) => {
    GetAddressDataApi.sendRequest({
      jwt: cookie.jwt,
      query: value,
    }).then((result) => setDadataAddresses(result?.filter((r) => r.data)));
  };

  const CalculateDelivery = async ({ address, zip, addressFull }) => {
    const [location] = await GetCdekLocationApi.sendRequest({
      jwt: cookie.jwt,
      body: {
        country_codes: [address?.data?.country_iso_code],
        postal_code: address?.data?.postal_code,
        city: address?.data?.city,
      },
    });
    const packages = order?.products?.map((p) => ({
      height: +p?.product?.parameters?.height || 0,
      length: +p?.product?.parameters?.length || 0,
      weight: +p?.product?.parameters?.brutto || 0,
      width: +p?.product?.parameters?.width || 0,
    }));

    const result = await GetCdekCalculatorTariffApi.sendRequest({
      body: {
        tariff_code: deliveryVariant == 'pvz' ? 136 : 137,
        from_location: {
          code: 44,
        },
        to_location: {
          code: location.code,
        },
        services: [
          {
            code: 'CARTON_BOX_XS',
            parameter: '2',
          },
        ],
        packages: packages,
      },
    });

    selectAddressHandler({
      deliveryType: 'CDEK',
      deliveryVariant: deliveryVariant,
      toLocationCode: location.code,
      fromLocationCode: 44,
      toLocationString: addressFull,
      fromLocationString: 'Москва',
      deliveryTotalSum: result?.total_sum,
      city: location.city,
      country: location.country,

      zip,
      packType: 2,
      calendarMin: result.calendar_min,
      calendarMax: result.calendar_max,
    });
  };
  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={homeAddress?.city}
          label="Город"
          onChange={({ target: { value } }) =>
            setHomeAddress({ ...homeAddress, city: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={homeAddress?.zip}
          label="Индекс"
          onChange={({ target: { value } }) =>
            setHomeAddress({ ...homeAddress, zip: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Stack>

      <TextField
        fullWidth
        sx={{ mt: 2 }}
        value={homeAddress?.street}
        label="Улица"
        onChange={({ target: { value } }) =>
          setHomeAddress({ ...homeAddress, street: value })
        }
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={homeAddress?.house}
          label="Дом"
          onChange={({ target: { value } }) =>
            setHomeAddress({ ...homeAddress, house: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />

        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={homeAddress?.flat}
          label="Квартира/помещение"
          onChange={({ target: { value } }) =>
            setHomeAddress({ ...homeAddress, flat: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Stack>

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
        disabled={Object.values(homeAddress)?.some((ha) => !ha)}
        onClick={async () => {
          await changeAddress(
            `${homeAddress?.zip}, ${homeAddress?.city}, ${homeAddress?.street}, д. ${homeAddress?.house}, кв. ${homeAddress?.flat}`,
          );
          setOpenHomeAddressDrawer(true);
        }}
      >
        Подтвердить адрес
      </Button>

      <Drawer
        anchor={'bottom'}
        open={openHomeAddressDrawer}
        onClose={() => setOpenHomeAddressDrawer(false)}
      >
        <Box sx={{ p: 3 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            {GetCdekLocationApi.loading && (
              <CircularProgress
                size="small"
                sx={{ width: '15px', height: '15px' }}
              />
            )}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Пожалуйста, уточните адрес
            </Typography>
          </Stack>
          {!!dadataAddresses?.length ? (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              onChange={({ target: { value } }) => {
                const address = JSON.parse(value);
                CalculateDelivery({
                  address,
                  zip: address.data.postal_code,
                  addressFull: address.unrestricted_value,
                });
              }}
              name="radio-buttons-group"
            >
              {dadataAddresses?.map((address) => (
                <FormControlLabel
                  key={address.unrestricted_value}
                  disabled={GetCdekLocationApi.loading}
                  value={JSON.stringify(address)}
                  control={<Radio />}
                  label={`${address.unrestricted_value}`}
                />
              ))}
            </RadioGroup>
          ) : (
            <Typography>
              Мы не смогли найти указанный адрес, пожалуйста проверьте
              правильность введенных данных
            </Typography>
          )}
        </Box>
      </Drawer>
    </>
  );
};
