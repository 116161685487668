import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { Typography } from '@mui/material';
import { url } from '../utils/url';

export const OffertaPage = () => {
  return (
    <MainLayout
      activeNavigate={url?.profile}
      title="Оферта"
      titleAs="h6"
      hiddenNavigate={false}
    >
      <Typography sx={{ mb: 2 }} as="h2" variant="h5">
        Оферта
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Клиент - дееспособное физическое лицо, либо юридическое лицо,
        представленное его законным представителем, приобретающее товары на
        сайте pinkieshop.ru или в телеграм боте @PinkiePieSecretShop_Bot.,
        достигший возраста 18 лет.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Поставщик – Общество с ограниченной ответственностью "ПОСТАВЩИК СЧАСТЬЯ"
        (ИНН 7705913620, адрес: 115280, г.Москва, ул. Автозаводская, дом 16,
        корп 2, стр 8, эт 2.).
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Исполниетль – Индивидуальный предприниматель Михеев Иван Михайлович, ИНН
        519001127905.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Заказ - оформленная заявка Клиента на поставку того или иного товара или
        товаров, выбранных на сайте pinkieshop.ru или в телеграм боте
        @PinkiePieSecretShop_Bot.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Служба доставки - собственная служба доставки Продавца., почта России,
        сети постаматов и пунктов выдачи.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Транспортные компании - третьи лица, оказывающие по договору с Продавцом
        услуги по доставке Заказов Клиентам.
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'justify' }} as="p" variant="p">
        Стороны - Исполниетль, Продавец и Клиент.
      </Typography>
      <ol>
        <li>
          Поряд работы и обязанности Сторон
          <ol>
            <li>
              Клиент - регистрируется, изменяет свои данные, ведет переписку,
              оформляет заказы и совершает платежи на сайте Исполниетля
              pinkieshop.ru или телеграм боте @PinkiePieSecretShop_Bot
            </li>
            <li>
              Исполнитель - Предоставляет в сети интернет рекламные материалы
              товаров Продовца, размещает информацию о товарах и услугах
              продавца на сайте pinkieshop.ru или телеграм боте
              @PinkiePieSecretShop_Bot, принимает заявки от Клиента о ворзврате,
              замене или гарантиях на товар, преедает заказы продовцу, принимает
              оплату от Клиента, самостоятельно проводит взаиморасчеты с
              Продавцом, организовывает скидки и акции для клиента, а также
              полностью отвечает за работоспособность сайта pinkieshop.ru и
              телеграм бота @PinkiePieSecretShop_Bot
            </li>
            <li>
              Продавец - Принимает от Исполнителя заказы Клиенты, отвечает за
              комлектовку заказу, оформление доставки до Клиента, выдачу заказов
              со склада и прием возвращенных товаров
            </li>
          </ol>
        </li>
        <li>
          Общие положения
          <ol>
            <li>
              Настоящий документ является публичной офертой в соответствии со
              ст. 435 и ч. 2 ст. 437 ГК РФ.
            </li>
            <li>
              Исполнитель оставляет за собой право вносить изменения в настоящий
              документ, расположенный на сайте pinkieshop.ru или в телеграм боте
              @PinkiePieSecretShop_Bot, в связи с чем Клиент обязуется
              самостоятельно отслеживать изменения в нём.
            </li>
            <li>
              Клиент соглашается с условиями настоящей оферты нажатием кнопок
              "Начать" (при использовании телеграм бота
              @PinkiePieSecretShop_Bot) и команды "/start" (при использовании
              телеграм бота @PinkiePieSecretShop_Bot), "Купить", "Оплатить",
              "Перейти к оплате" на последнем этапе оформления Заказа при
              использовании сайта pinkieshop.ru или телеграм бота, телеграм
              мини-апп приложения @PinkiePieSecretShop_Bot.
            </li>
          </ol>
        </li>

        <li>
          Оформление и сроки выполнения Заказа
          <ol>
            <li>
              Заказ Клиента может быть оформлен следующими способами: Клиентом
              самостоятельно на Сайте или в телеграм боте
              @PinkiePieSecretShop_Bot.
            </li>
            <li>
              При оформлении Заказа Клиент должен указать фамилию, имя, номер
              телефона, e-mail адресс, а также предпочитаемый способ получения
              товара
            </li>
            <li>
              После оформления заказа Клиенту предоставляется информация в
              телеграм бот @PinkiePieSecretShop_Bot о состоянии Заказа с
              указанием конечной стоимости заказа
            </li>
            <li>
              В случае отсутствия нужного товара на момент оформления заказа в
              течении суток с клиентом связывается оператор Продовца
            </li>
            <li>
              Клиент обязуется произвести 100% предоплату за заказ и доставку.
            </li>
            <li>
              Все информационные материалы, представленные на сайте Продавца,
              носят справочный характер и могут не в полной мере передавать
              достоверную информацию о свойствах и характеристиках товара,
              включая цвета, размеры и формы. В случае возникновения у Клиента
              вопросов, касающихся свойств и характеристик товара, перед
              оформлением Заказа Клиент должен обратиться к Исполнителю.
            </li>
          </ol>
        </li>
        <li>
          Доставка
          <ol>
            <li>
              Способы доставки товаров указаны на сайте (pinkieshop.ru) или в
              телеграм боте (@PinkiePieSecretShop_Bot) Исполнителя на странице
              оформления заказа
            </li>
            <li>
              В случае доставки курьерской службой Продавца, сроки, дата и
              временной интервал доставки согласовывается с Клиентом, либо
              Клиент самостоятельно забирает заказ в магазине Продавца,
              предварительно созвонившись с оператором магазина Продавца
            </li>
            <li>
              Продавец приложит все усилия для соблюдения сроков доставки, тем
              не менее, задержки в доставке возможны ввиду непредвиденных
              обстоятельств, произошедших не по вине Продавца.
            </li>
            <li>
              Доставка Заказа осуществляется на почтовый адрес Клиента, в случае
              отправки почтой России, или в пункт выдачи Заказов по адресу
              указанному Клиентом при оформлении Заказа
            </li>
            <li>
              При доставке Заказ вручается Клиенту либо лицу, являющемуся
              законным представителем Клиента. При невозможности получения
              Заказа, оформленного за наличный расчет, указанными выше лицами,
              Заказ вручается лицу, готовому предоставить сведения о заказе
              (номер Заказа и/или ФИО Получателя), а также оплатить стоимость
              Заказа в полном объеме лицу, осуществляющему доставку Заказа.
            </li>
            <li>
              При доставке предоплаченного Заказа Клиент обязан предоставить
              документ, удостоверяющий личность. Продавец гарантирует
              конфиденциальность и защиту персональной информации Клиента.
            </li>
            <li>
              При передаче Заказа Клиент должен проверить внешний вид и упаковку
              Заказа, количество товара в Заказе, комплектность, ассортимент.
            </li>
            <li>
              Неявка Клиента или не совершение иных необходимых действий для
              принятия Заявки могут рассматриваться Продавцом в качестве отказа
              Клиента от исполнения Договора.
            </li>
          </ol>
        </li>
        <li>
          Оплата Товара
          <ol>
            <li>
              Цена и способы оплаты товара и доставки Заказа указываются на
              сайте или в телеграм боте @PinkiePieSecretShop_Bot.
            </li>
            <li>
              Стоимость товара фиксируется в момент оформления Заказа и не
              меняется в процессе обработки, за исключением случаев, указанных в
              пункте 4.3.
            </li>
            <li>
              В случае неверного указания цены заказанного Клиентом Товара,
              Продавец при первой возможности информирует об этом Клиента для
              подтверждения Заказа по исправленной цене либо аннулирования
              Заказа. При невозможности связаться с Клиентом данный Заказ
              считается аннулированным. Если Заказ был оплачен, Исполнитель
              возвращает Клиенту оплаченную за Заказ сумму.
            </li>
            <li>
              Оплата Заказа при доставке по России производится по 100%
              предоплате в процессе оформления и формирования Заказа
            </li>

            <li>
              Особенности оплаты Товара с помощью банковских карт
              <ol>
                <li>
                  В соответствии с положением ЦБ РФ "Об эмиссии банковских карт
                  и об операциях, совершаемых с использованием платежных карт"
                  от 24.12.2004 №266-П, операции по банковским картам
                  совершаются держателем карты либо уполномоченным им лицом
                </li>
                <li>
                  Авторизация операций по банковским картам осуществляется
                  банком. Если у банка есть основания полагать, что операция
                  носит мошеннический характер, то банк вправе отказать в
                  осуществлении данной операции. Мошеннические операции с
                  банковскими картами попадают под действие статьи 159 УК РФ
                </li>
                <li>
                  Во избежание случаев различного рода неправомерного
                  использования банковских карт при оплате, все Заказы,
                  оформленные на Сайте и предоплаченные банковской картой,
                  проверяются Продавцом. Согласно Правилам международных
                  платежных систем, в целях проверки личности владельца и его
                  правомочности на использование карты Клиент, оформивший такой
                  заказ, обязан по запросу, поступившему от сотрудника Продавца,
                  предоставить копию двух страниц паспорта владельца банковской
                  карты - разворота с фотографией, а также копию банковской
                  карты с обеих сторон (номер карты нужно закрыть, кроме
                  последних четырех цифр). Продавец оставляет за собой право без
                  объяснения причины аннулировать Заказ, в том числе в случае
                  непредставления указанных документов Клиентом (по факсу или по
                  электронной почте в виде сканированных копий) в течение 14
                  дней с даты оформления Заказа или наличия сомнений в их
                  подлинности. Стоимость Заказа возвращается на карту владельца.
                </li>
              </ol>
            </li>
            <li>
              Продавец и Исполнитель вправе предоставлять Клиенту скидки на
              Товар и устанавливать программу бонусов. Виды скидок, бонусов,
              порядок и условия начисления указаны на Сайте в разделе "Скидки и
              акции" и могут быть изменены Продавцом или Исполнителем в
              одностороннем порядке
            </li>
            <li>
              Продавец и Исполнитель вправе устанавливать скидки в целях
              продвижения того или иного способа оплаты либо доставки Товара.
            </li>
          </ol>
        </li>
        <li>
          Возврат Товара
          <ol>
            <li>
              Возврат товара надлежащего качества
              <ol>
                <li>
                  Клиент вправе отказаться от заказанного Товара в любое время
                  до его получения, а после получения Товара - в течение 14
                  дней, не считая дня покупки. Возврат Товара надлежащего
                  качества возможен в случае, если сохранены его товарный вид,
                  потребительские свойства, а также документ, подтверждающий
                  факт и условия покупки указанного Товара.
                </li>
                <li>
                  При отказе Клиента от Товара, согласно п. 5.1.1. настоящей
                  оферты, Исполнитель возвращает ему стоимость Товара, за
                  исключением расходов продавца на доставку от Клиента
                  возвращенного Товара, не позднее чем через 10 дней с даты
                  получения Продавцом письменного заявления Клиента.
                </li>
                <li>
                  В течение 14 дней с момента получения Заказа, не считая дня
                  покупки, Клиент вправе обменять товар надлежащего качества на
                  аналогичный товар, если указанный товар не подошел Клиенту по
                  форме, габаритам, фасону, расцветке, размеру или комплектации.
                  Клиент не вправе обменять Товары надлежащего качества,
                  указанные в Перечне непродовольственных товаров надлежащего
                  качества, не подлежащих возврату или обмену, утвержденном
                  Постановлением Правительства РФ от 19.01.1998 № 55.
                </li>
                <li>
                  Если на момент обращения Клиента аналогичный товар отсутствует
                  в продаже у Продавца, Клиент вправе отказаться от исполнения
                  договора купли-продажи и потребовать возврата уплаченной за
                  указанный товар денежной суммы. Продавец обязан уведомить
                  Исполнителя об отказе, а Исполниетль обязан вернуть уплаченную
                  за возвращенный товар денежную сумму в течение 7 дней со дня
                  требования.
                </li>
              </ol>
            </li>
            <li>
              Возврат товара ненадлежащего качества
              <ol>
                <li>
                  Клиент может возвратить Товар ненадлежащего качества
                  изготовителю или Продавцу и потребовать возврата уплаченной
                  денежной суммы в течение гарантийного срока, срока годности
                  либо, если такой срок не установлен, в разумный срок, не
                  превышающий два года. Клиент также может потребовать замены
                  Товара ненадлежащего качества либо устранения недостатков
                </li>
                <li>
                  В случае отказа Клиента от договора и предъявления требования
                  о возврате уплаченной за товар денежной суммы, стоимость
                  Товара подлежит возврату Клиенту в течение 7 дней с момента
                  получения Исполнителем письменного заявления или заявки в
                  телеграм боте @PinkiePieSecretShop_Bot от Клиента.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Заполнение форм на Сайте
          <ol>
            <li>
              Клиент согласен с тем, что любые персональные данные Клиента при
              использовании им сайта pinkieshop.ru или телеграм бота
              @PinkiePieSecretShop_Bot будут храниться в информационной системе
              Исполнителя и Продавца и будут обрабатываться исключительно для
              целей обеспечения исполнения настоящей Оферты в соответствии с
              Федеральным законом "О персональных данных".
            </li>
            <li>
              Продавец и Исполнитель не несет ответственности за точность и
              правильность информации, предоставляемой Клиентом.
            </li>
            <li>
              Клиент обязуется не сообщать третьим лицам пароль, полученный в
              результате регистрации, токены авторизации, chat_id,
              идентификаторы телеграм и другие данные авторизации на сайте
              pinkieshop.ru или телеграм боте @PinkiePieSecretShop_Bot. В случае
              возникновения у Клиента подозрений относительно безопасности его
              логина и пароля или возможности их несанкционированного
              использования третьими лицами, Клиент обязуется незамедлительно
              уведомить об этом Исполнителя.
            </li>
          </ol>
        </li>
        <li>
          Интеллектуальная собственность
          <ol>
            <li>
              Вся текстовая информация и графические изображения, находящиеся на
              Сайте, являются собственностью Продавца и/или Исполнителя и/или
              его контрагентов.
            </li>
          </ol>
        </li>
        <li>
          Гарантии и ответственность
          <ol>
            <li>
              Продавец и/или Исполниетль не несет ответственности за ущерб,
              причиненный Клиенту вследствие ненадлежащего использования
              Товаров, заказанных на Сайте.
            </li>
            <li>
              Продавец и/или Исполниетль вправе переуступать или каким-либо иным
              способом передавать свои права и обязанности, вытекающие из его
              отношений с Клиентом, третьим лицам.
            </li>
          </ol>
        </li>
        <li>
          Конфиденциальность и защита персональной информации.
          <ol>
            <li>
              При заполнении различных форм на сайте или телеграм боте
              (@PinkiePieSecretShop_Bot) Исполнителя или Продавца Клиент
              предоставляет свои персональные данные.
            </li>
            <li>
              Предоставляя свои персональные данные на сайте или телеграм боте
              (@PinkiePieSecretShop_Bot), Клиент соглашается на их обработку
              Продавцом и Исполнителем, в том числе и в целях продвижения
              Продавцом и Исполнителем товаров и услуг. Если Клиент не желает,
              чтобы его персональные данные обрабатывались и хранились, то он
              должен обратиться в службу по работе с клиентами Продавца или
              Исполнителя. В таком случае вся полученная от Клиента информация
              удаляется из информационной системы Продавца и Исполнителя до
              оформления Клиентом следующего заказа.
            </li>
            <li>
              Продавец и Исполниетль используют информацию
              <ol>
                <li>
                  для регистрации Клиента на сайте или телеграм боте
                  (@PinkiePieSecretShop_Bot)
                </li>
                <li>для выполнения своих обязательств перед Клиентом;</li>
                <li>
                  для создания статистических и аналитических отчётов
                  исключительно для внутренних нужд Продавца и Исполнителя
                </li>
                <li>
                  для определения победителя в акциях, проводимых Продавцом или
                  Исполнителем
                </li>
              </ol>
            </li>
            <li>
              Продавец и Исполниетль вправе направлять Клиенту сообщения
              информационного характера и сообщения, касающиеся приобретённых
              товаров, а также рекомендаций будущих покупок. Если Клиент не
              желает получать рассылки от Продавца, он должен перейти по
              соответствующей ссылке внутри сообщения
            </li>
            <li>
              Продавец и Исполниетль обязуются не разглашать полученную от
              Клиента информацию. Не считается нарушением предоставление
              Продавцом или Исполнителем информации агентам и третьим лицам,
              действующим на основании договора с Продавцом или Исполнителем,
              для исполнения обязательств перед Клиентом.
            </li>
            <li>
              Продавец и Исполнитель вправе использовать технологию "cookies".
              "Cookies" не содержат конфиденциальную информацию и не передаются
              третьим лицам.
            </li>
            <li>
              Продавец и Исполниетль не несут ответственности за сведения,
              предоставленные Клиентом на сайте или телеграм боте
              @PinkiePieSecretShop_Bot в общедоступной форме.
            </li>
            <li>
              Продавец и Исполниетль вправе собирать данные о поведении Клиента
              на сайте Продавца или Исполнителя или в телеграм боте Исполнителя
              @PinkiePieSecretShop_Bot для аналитических нужд.
            </li>
          </ol>
        </li>
        <li>
          Прочие условия
          <ol>
            <li>
              К отношениям между Клиентом, Продавцом и Исполнителем применяется
              право Российской Федерации.
            </li>
            <li>
              В случае возникновения вопросов и претензий со стороны Клиента, он
              должен обратиться в службу по работе с клиентами Исполнителя через
              телеграм бот @PinkiePieSecretShop_Bot. Все возникающее споры
              стороны будут стараться решить путем переговоров, при недостижении
              соглашения спор будет передан на рассмотрение в судебный орган в
              соответствии с действующим законодательством РФ.
            </li>
          </ol>
        </li>
      </ol>
    </MainLayout>
  );
};
