import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { OrderFooter } from '../components/Order/OrderFooter';
import { OrderStepper } from '../components/Order/OrderStepper';
import { url } from '../utils/url';
import { useOrder } from '../hooks/useOrder';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { add, format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import { useApi } from '../hooks/useApi';
import { CheckPromocode } from '../api/PromocodeApi';
import { useCookies } from 'react-cookie';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const OrderFinalPage = () => {
  const [startReservationOrder, setStartReservationOrder] = useState(false);
  const {
    order,
    orderLoading,
    ReservationOrderHandler,
    SetOrderPromocodeHandler,
    RejectOrderPromocodeHandler,
  } = useOrder({});
  const navigate = useNavigate();
  const [promocodeName, setPromocodeName] = useState('');
  const [promocode, setPromocode] = useState({});

  const [cookie] = useCookies('jwt');
  const CheckPromocodeApi = useApi(CheckPromocode);

  const CheckPromocodeHandler = async () => {
    const result = await CheckPromocodeApi.sendRequest({
      jwt: cookie.jwt,
      body: {
        name: promocodeName,
      },
    });
    if (result) {
      await SetOrderPromocodeHandler({ promocode: result });
      setPromocode(result);
    } else {
      setPromocode(false);
    }
  };

  const ReductOrderDeliverySale = () => {
    let needForFree = 0;
    let totalPercent = 0;
    let sum = 0;
    const getSum = (s) => {
      return (
        s -
        +order?.products
          ?.map((p) => +p.finalPrice * +p.count)
          ?.reduce((a, b) => a + b, 0)
      );
    };
    if (order?.delivery.toLocationCode === 44) {
      sum = getSum(2000);
      needForFree = sum < 0 ? 0 : sum;
      totalPercent = ((2000 - needForFree) / 2000) * 100;
    } else {
      sum = getSum(4000);
      needForFree = sum < 0 ? 0 : sum;
      totalPercent = ((4000 - needForFree) / 4000) * 100;
    }
    return {
      needForFree,
      totalPercent,
      sum,
    };
  };
  return (
    <MainLayout
      activeNavigate={url?.cart}
      title="Оформление заказа"
      titleAs="h6"
      hiddenNavigate={true}
    >
      <OrderStepper orderId={order?.id} />

      <Card sx={{ mb: 3 }}>
        <CardContent>
          {!order?.promocode?.id && (
            <>
              <Stack direction="row" spacing={2}>
                <PercentOutlinedIcon />
                <Box>
                  <Typography>У вас есть промокод?</Typography>
                </Box>
              </Stack>
              <TextField
                label="Промокод"
                fullWidth
                sx={{ mt: 2 }}
                value={promocodeName}
                onChange={({ target: { value } }) => setPromocodeName(value)}
              />
              <LoadingButton
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => CheckPromocodeHandler()}
                loading={orderLoading}
              >
                Применить
              </LoadingButton>
            </>
          )}

          {!promocode ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              Промокод не действителен. Попробуйте применить другой промокод
            </Alert>
          ) : (
            order?.promocode?.id && (
              <Alert severity="success">
                <Typography>
                  Промокод {promocodeName} успешно применен
                </Typography>
                <LoadingButton
                  variant="outlined"
                  color="error"
                  startIcon={<ClearOutlinedIcon />}
                  loading={orderLoading}
                  fullWidth
                  onClick={() => {
                    setPromocode({});
                    RejectOrderPromocodeHandler();
                  }}
                  sx={{ mt: 2 }}
                >
                  Удалить промокод
                </LoadingButton>
              </Alert>
            )
          )}
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: 'bold' }}>Доставка</Typography>
            <Button
              size="small"
              onClick={() => navigate(`/order/${order?.id}/delivery`)}
            >
              Изменить
            </Button>
          </Stack>
          <Divider sx={{ my: 2 }} />
          {order?.delivery?.deliveryType == 'CDEK' ? (
            <>
              <Typography>
                Доставка до{' '}
                {order?.delivery?.deliveryVariant == 'home'
                  ? 'двери'
                  : 'пункта выдачи'}
                , по адресу - {order?.delivery?.toLocationString}
              </Typography>
              <Typography sx={{ fontWeight: 'bold', ml: 'auto' }}>
                {format(
                  add(new Date(), {
                    days: order?.delivery?.calendarMin,
                  }),
                  'dd.MM.yyyy',
                )}{' '}
                -{' '}
                {format(
                  add(new Date(), {
                    days: order?.delivery?.calendarMax,
                  }),
                  'dd.MM.yyyy',
                )}
              </Typography>
            </>
          ) : order?.delivery?.deliveryType == 'COURIER' ? (
            <>
              <Typography>
                Доставка курьером до адреса -{' '}
                {order?.delivery?.toLocationString}
              </Typography>
              <Typography sx={{ fontWeight: 'bold', ml: 'auto' }}>
                с 10:00 до 19:00 по будням
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Самовывоз по адресу - г. Москва, ул. Автозаводская, дом 16,
                корпус 2, строение 8.
              </Typography>
              <Typography sx={{ fontWeight: 'bold', ml: 'auto' }}>
                с 9:00 до 21:00 ежедневно
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: 'bold' }}>Контакты</Typography>
            <Button
              size="small"
              onClick={() => navigate(`/order/${order?.id}/customer`)}
            >
              Изменить
            </Button>
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography>{order?.customer?.phone}</Typography>
          <Typography>{order?.customer?.email}</Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 10 }}>
        <CardContent>
          <Typography>Итог</Typography>
          <Divider sx={{ my: 2 }} />

          {!!(order?.delivery?.deliveryType !== 'SELF') &&
            !ReductOrderDeliverySale() === 0 && (
              <Card
                variant="outlined"
                sx={{
                  background:
                    ReductOrderDeliverySale().needForFree == 0
                      ? '#daffdd'
                      : '#fff9da',
                  border: 'none !important',
                  mb: 2,
                }}
              >
                <CardContent>
                  {ReductOrderDeliverySale().needForFree == 0 ? (
                    <Typography>У вас бесплатная доставка!</Typography>
                  ) : (
                    <Typography>Бесплатаная доставка</Typography>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ width: '100%', mr: 1 }}>
                      {}
                      <LinearProgress
                        variant="determinate"
                        value={ReductOrderDeliverySale().totalPercent}
                      />
                    </Box>
                    {!!ReductOrderDeliverySale().needForFree && (
                      <Box sx={{ width: '30%' }}>
                        <Typography variant="body2">
                          {ReductOrderDeliverySale().needForFree}
                          руб.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            )}

          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
            justifyContent="space-between"
          >
            <Typography>Товаров {order?.products?.length} шт.</Typography>
            <Typography sx={{ fontWeight: 'bold', ml: 'auto' }}>
              {
                +order?.products
                  ?.map((p) => +p.finalPrice * +p.count)
                  ?.reduce((a, b) => a + b, 0)
              }{' '}
              руб.
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
            justifyContent="space-between"
          >
            <Typography>Доставка</Typography>
            <Typography sx={{ fontWeight: 'bold', ml: 'auto' }}>
              {ReductOrderDeliverySale().needForFree == 0
                ? 0
                : order?.delivery?.deliveryFinalSum}{' '}
              руб.
            </Typography>
          </Stack>
          {!!order?.promocode?.id && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%' }}
              justifyContent="space-between"
            >
              <Typography>Скидка</Typography>
              <Typography sx={{ fontWeight: 'bold', ml: 'auto' }}>
                {+order?.products
                  ?.map((p) => +p.retailPrice * +p.count)
                  ?.reduce((a, b) => a + b, 0) +
                  +order?.delivery?.deliveryTotalSum -
                  (+order?.products
                    ?.map((p) => +p.finalPrice * +p.count)
                    ?.reduce((a, b) => a + b, 0) +
                    +order?.delivery?.deliveryFinalSum)}{' '}
                руб.
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
      <OrderFooter
        nextButtonText={`Оплатить ${
          +order?.products
            ?.map((p) => +p.finalPrice * +p.count)
            ?.reduce((a, b) => a + b, 0) +
          (ReductOrderDeliverySale().needForFree == 0
            ? 0
            : +order?.delivery?.deliveryFinalSum)
        } руб.`}
        loading={orderLoading}
        showBackButton={true}
        backButtonHandler={() => navigate(`/order/${order?.id}/customer`)}
        nextButtonHandler={async () => {
          setStartReservationOrder(true);
          const result = await ReservationOrderHandler({ promocode });
          if (result?.payment?.paymentURL) {
            setTimeout(() => {
              setStartReservationOrder(false);
              window.location = result?.payment?.paymentURL;
            }, 2000);
          } else if (order.status == 'sendToProvide' && order.payment == null) {
            navigate(`/order/${order.id}/result`);
          }
        }}
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        }}
        open={startReservationOrder}
      >
        <Stack sx={{ width: '70%', m: 'auto' }}>
          <CircularProgress color="inherit" />
          <Typography>
            Создаем заказ
            {order?.payment?.paymentURL &&
              ' и скоро перенаправим вас на оплату'}
            , пожалуйста не закрывайте это окно
          </Typography>
        </Stack>
      </Backdrop>
    </MainLayout>
  );
};
