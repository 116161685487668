import { TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';

export const OrderCustomerData = ({
  orderCustomer,
  setOrderCustomer,
  customerVariant,
  focus,
  setFocus,
  error,
}) => {
  return (
    <>
      {customerVariant == 'full' && (
        <Typography sx={{ mb: 1 }}>Личные данные</Typography>
      )}
      {customerVariant == 'full' && (
        <TextField
          label="Ваше имя"
          fullWidth
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={orderCustomer?.firstName}
          onChange={({ target: { value } }) =>
            setOrderCustomer({ ...orderCustomer, firstname: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      )}
      {customerVariant == 'full' && (
        <TextField
          label="Ваша фамилия"
          fullWidth
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={orderCustomer?.lastName}
          onChange={({ target: { value } }) =>
            setOrderCustomer({ ...orderCustomer, lastname: value })
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      )}
      <Typography sx={{ mb: 1 }}>Контакты</Typography>

      <InputMask
        label="Телефон для связи"
        fullWidth
        sx={{ mb: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
        value={orderCustomer?.phone}
        onChange={({ target: { value } }) =>
          setOrderCustomer({ ...orderCustomer, phone: value })
        }
        mask="+7(999)999-99-99"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        {(props) => <TextField {...props} />}
      </InputMask>

      <TextField
        label="Email для отправки чека"
        fullWidth
        sx={{ mb: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
        value={orderCustomer?.email}
        onChange={({ target: { value } }) =>
          setOrderCustomer({ ...orderCustomer, email: value })
        }
        error={error?.email}
        helperText={
          error?.email && 'Поле должно содержать реальный email телефона'
        }
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </>
  );
};
