import React, { useEffect, useState } from 'react';
import { GetAllPopularCategories } from '../api/ProductCategoriesApi';
import { useApi } from '../hooks/useApi';
import { MainLayout } from '../components/Layout/MainLayout';
import { ProductCategoriesItem } from '../components/ProductCategories/ProductCategoriesItem';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

import { useNavigate } from 'react-router-dom';
import { useMe } from '../hooks/useMe';
import { url } from '../utils/url';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper/modules';
import { useOrder } from '../hooks/useOrder';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { ProductViewed } from '../components/Product/ProductViewed';
import { ProductFilter } from '../api/ProductApi';
import { ProductItem } from '../components/Product/ProductItem';
import { SmallBanner } from '../components/Banner/SmallBanner';
import { PrevSliderButton } from '../components/Default/Slider/PrevSliderButton';
import { NextSliderButton } from '../components/Default/Slider/NextSliderButton';

export const HomePage = () => {
  const [allProductCategories, setAllProductCategories] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const { customer, requestMeHandler } = useMe();
  const { order, FindLastNotFinishedOrderHandler } = useOrder({
    customer,
    requestMeHandler,
  });

  const navigate = useNavigate();
  const getAllProductCategoriesApi = useApi(GetAllPopularCategories);
  const ProductFilterApi = useApi(ProductFilter);

  useEffect(() => {
    getAllProductCategoriesApi.sendRequest().then((result) => {
      setAllProductCategories(result);
    });
    ProductFilterApi.sendRequest({ isPopular: true }).then((result) =>
      setPopularProducts(result[0]),
    );
  }, []);

  const banners = [
    {
      title: 'Анонимная покупка',
      image: '/glasses-1.png',
      background: '#F9C28A',
    },
    {
      title: 'Бесплатная доставка',
      image: '/box-1.png',
      background: '#969FE2',
    },
    {
      title: 'Доставка от 1 часа',
      image: '/clock-1.png',
      background: '#C9FFF1',
    },
    {
      title: 'Доставка по России',
      image: '/plane-1.png',
      background: '#FBC42C',
    },
    {
      title: '32.000 товаров',
      image: '/cart-1.png',
      background: '#D0E1E8',
    },
  ];
  return (
    <MainLayout activeNavigate={url?.home}>
      {order?.id && (
        <Alert severity="warning" sx={{ my: 3 }}>
          <Typography>
            У вас есть начатый заказ, хотите продолжить оформление?
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => navigate(`/cart`)}
          >
            Продолжить
          </Button>
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            sx={{
              width: '100%',
              borderRadius: '0 0 20px 20px',
              border: 'none',
              mb: 3,
            }}
            variant="outlined"
          >
            <CardContent>
              <img
                src="/logo.png"
                style={{ width: '150px', marginTop: '15px' }}
              />
              <Typography
                as="h1"
                variant="h4"
                sx={{ mt: 2, fontWeight: 'bold', lineHeight: 1, mb: 1 }}
              >
                Для тех кто умеет любить 🩷
              </Typography>
              <Typography as="h2" sx={{ mb: 3, opacity: 0.8 }}>
                Более{' '}
                <Typography
                  as="span"
                  sx={{ fontWeight: 'bold' }}
                  color="primary"
                >
                  18 000
                </Typography>{' '}
                товаров для ваших желаний
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(url?.shop)}
                startIcon={<GridViewOutlinedIcon />}
              >
                Перейти в каталог
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            loop={true}
            modules={[FreeMode]}
            className="mySwiper"
          >
            <PrevSliderButton top="30%" />

            {banners?.map((banner) => (
              <SwiperSlide
                style={{
                  overflow: 'hidden',
                  background: 'none',
                  textAlign: 'left',
                }}
              >
                <SmallBanner
                  title={banner.title}
                  image={banner.image}
                  background={banner.background}
                />
              </SwiperSlide>
            ))}
            <NextSliderButton top="30%" />
          </Swiper>
        </Grid>
      </Grid>
      {!!allProductCategories?.length && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography as="h2" variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
              Популярные категории
            </Typography>
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              freeMode={true}
              loop={true}
              modules={[FreeMode]}
              className="mySwiper"
            >
              <PrevSliderButton top="30%" />
              {allProductCategories?.map((category) => (
                <SwiperSlide
                  style={{
                    overflow: 'hidden',
                    background: 'none',
                    textAlign: 'left',
                  }}
                >
                  <ProductCategoriesItem category={category} fontSize="12px" />
                </SwiperSlide>
              ))}
              <NextSliderButton top="30%" />
            </Swiper>
          </Grid>
        </Grid>
      )}

      {!!popularProducts?.length && (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <Typography as="h2" variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
              Популярные товары
            </Typography>
            <Swiper
              slidesPerView={2}
              spaceBetween={15}
              freeMode={true}
              modules={[FreeMode]}
              loop={true}
              className="mySwiper"
            >
              <PrevSliderButton top="20%" />
              {popularProducts?.map((product) => (
                <SwiperSlide
                  style={{
                    overflow: 'hidden',
                    background: 'none',
                    textAlign: 'left',
                  }}
                >
                  <ProductItem
                    product={product}
                    category={product?.category}
                    customer={customer}
                  />
                </SwiperSlide>
              ))}
              <NextSliderButton top="20%" />
            </Swiper>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductViewed customer={customer} />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
