import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { OrderFooter } from './OrderFooter';
import { useNavigate } from 'react-router-dom';

import { useApi } from '../../hooks/useApi';
import { GetAddressData } from '../../api/DadataApi';

import { add, format } from 'date-fns';
import { CdekDeliveryToHome } from './CdekDeliveryToHome';
import { CdekDeliveryToPVZ } from './CdekDeliveryToPVZ';

export const CdekDeliveryScreen = ({
  order,
  UpdateOrderHandler,
  GoToOrderStep,
}) => {
  const [deliveryVariant, setDeliveryVariant] = useState('home');

  const [finalDeliveryData, setFinalDeliveryData] = useState({});
  const [focus, setFocus] = useState(false);
  const navigate = useNavigate();
  const GetAddressDataApi = useApi(GetAddressData);

  const dropDeliveryFromInterface = () => {
    setFinalDeliveryData({});
  };

  return (
    <Box
      sx={{
        height: focus ? `calc(${window?.screen?.height}px - 20vh)` : 'auto',
      }}
    >
      <Typography as="h6" variant="h6" sx={{ mb: 2 }}>
        Выберите удобный способ получения заказа
      </Typography>
      <Stack direction="row" spacing={1}>
        <Card
          variant="outlined"
          sx={
            deliveryVariant == 'home'
              ? { border: 'solid 1px #d15edc !important', mb: 2 }
              : { mb: 2 }
          }
          onClick={() => {
            setDeliveryVariant('home');
            dropDeliveryFromInterface();
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: '14px' }}>Доставка до двери</Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={
            deliveryVariant == 'pvz'
              ? { border: 'solid 1px #d15edc !important', mb: 2 }
              : { mb: 2 }
          }
          onClick={() => {
            setDeliveryVariant('pvz');
            dropDeliveryFromInterface();
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: '14px' }}>Доставка до ПВЗ</Typography>
          </CardContent>
        </Card>
      </Stack>

      {!finalDeliveryData?.deliveryTotalSum ? (
        <>
          {deliveryVariant == 'home' && (
            <CdekDeliveryToHome
              deliveryVariant={deliveryVariant}
              order={order}
              selectAddressHandler={(data) => setFinalDeliveryData(data)}
              focus={focus}
              setFocus={(data) => setFocus(data)}
            />
          )}

          {(deliveryVariant == 'pvz' || deliveryVariant == 'postmat') && (
            <CdekDeliveryToPVZ
              order={order}
              deliveryVariant={deliveryVariant}
              selectAddressHandler={(data) => setFinalDeliveryData(data)}
              focus={focus}
              setFocus={(data) => setFocus(data)}
            />
          )}
        </>
      ) : (
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Typography fontWeight="bold">Адрес:</Typography>
            <Typography>
              {deliveryVariant == 'home'
                ? `${finalDeliveryData?.toLocationString}`
                : `ПВЗ/Постамат - ${finalDeliveryData?.toLocationString}`}
            </Typography>

            <Typography sx={{ mt: 1 }} fontWeight="bold">
              Срок
            </Typography>
            <Typography>
              {format(
                add(new Date(), {
                  days: finalDeliveryData?.calendarMin,
                }),
                'dd.MM.yyyy',
              )}{' '}
              до{' '}
              {format(
                add(new Date(), {
                  days: finalDeliveryData?.calendarMax,
                }),
                'dd.MM.yyyy',
              )}
            </Typography>
            <Typography sx={{ mt: 1 }} fontWeight="bold">
              Стоимость
            </Typography>
            <Typography>{finalDeliveryData?.deliveryTotalSum} руб.</Typography>
            <Button
              variant="outlined"
              color="info"
              sx={{ mt: 2 }}
              fullWidth
              onClick={() => {
                dropDeliveryFromInterface();
              }}
            >
              Изменить
            </Button>
          </CardContent>
        </Card>
      )}
      <OrderFooter
        nextButtonText="Продолжить"
        loading={GetAddressDataApi.loading}
        disabled={!Object.keys(finalDeliveryData)?.length}
        showBackButton={true}
        backButtonHandler={() => navigate(`/order/${order.id}/delivery`)}
        nextButtonHandler={async () => {
          let id = {};
          if (order?.delivery?.id) {
            id = {
              id: order?.delivery?.id,
            };
          }
          await UpdateOrderHandler({
            ...order,
            delivery: { ...finalDeliveryData, ...id },
          });
          GoToOrderStep();
        }}
      />
    </Box>
  );
};
