import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { url } from '../utils/url';
import { useCookies } from 'react-cookie';
import { useApi } from '../hooks/useApi';
import { GetMyOrders, GetOrderById } from '../api/OrderApi';

import { format } from 'date-fns';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { OrderItem } from '../components/Order/OrderItem';
import { OrderStatusMapper } from '../utils/orderStatusMapper';
import { useNavigate } from 'react-router-dom';
import { useOrder } from '../hooks/useOrder';
import { useMe } from '../hooks/useMe';
export const ProfileOrderHistoryPage = () => {
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [cookie] = useCookies('jwt');
  const [selectedOrder, setSelectedOrder] = useState({});
  const { customer, requestMeHandler } = useMe();
  const { order, FindLastNotFinishedOrderHandler } = useOrder({
    customer,
    requestMeHandler,
  });

  const GetMyOrdersApi = useApi(GetMyOrders);
  const GetOrderByIdApi = useApi(GetOrderById);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookie.jwt) {
      GetMyOrdersHandler();
    }
  }, [cookie]);
  const GetMyOrdersHandler = async (offset = page - 1, limit = 16) => {
    const result = await GetMyOrdersApi.sendRequest({
      jwt: cookie.jwt,
      limit,
      offset,
    });
    setOrders(result[0]);
    setOrdersCount(result[1]);
  };

  const GetOneOrderHandler = async (orderId) => {
    const result = await GetOrderByIdApi.sendRequest({
      jwt: cookie.jwt,
      orderId,
    });
    setSelectedOrder(result);
  };

  return (
    <MainLayout activeNavigate={url?.profile} title="История заказов">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {order?.id && (
            <Alert severity="warning" sx={{ my: 3 }}>
              <Typography>
                У вас есть начатый заказ, хотите продолжить оформление?
              </Typography>
              <Button
                color="primary"
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => navigate(`/cart`)}
              >
                Продолжить
              </Button>
            </Alert>
          )}
          {orders?.length ? (
            orders?.map(
              (order) =>
                !!order?.products?.length && (
                  <Card key={order.id}>
                    <CardContent>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Box>
                          <Typography>
                            Заказ от {format(order?.updated, 'dd.MM.yyyy')}{' '}
                            <Chip
                              color={OrderStatusMapper(order.status)?.color}
                              label={OrderStatusMapper(order.status)?.label}
                              size="small"
                              sx={{ ml: 2 }}
                            />
                          </Typography>

                          <Typography sx={{ fontWeight: 'bold' }}>
                            {order?.products
                              ?.map((p) => +p.finalPrice)
                              ?.reduce((a, b) => {
                                return a + b;
                              }) +
                              (+order?.delivery?.deliveryFinalSum || 0)}{' '}
                            руб.
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            onClick={() => GetOneOrderHandler(order?.id)}
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                ),
            )
          ) : (
            <>
              <Typography>Вы еще не добавили товары в корзину</Typography>
              <Button
                sx={{ mt: 3 }}
                color="primary"
                variant="contained"
                onClick={() => navigate(url.home)}
              >
                Перейти в каталог
              </Button>
            </>
          )}
        </Grid>
        {!!ordersCount && (
          <Grid item xs={12}>
            <Pagination
              sx={{ my: 5 }}
              count={Math.ceil(ordersCount / 16)}
              variant="outlined"
              shape="rounded"
              onChange={(event, value) => {
                setPage(value);
                GetMyOrders({ jwt: cookie.jwt, offset: value - 1 });
              }}
            />
          </Grid>
        )}
      </Grid>

      <Drawer
        anchor={'bottom'}
        open={selectedOrder?.id}
        onClose={() => setSelectedOrder({})}
      >
        <Box sx={{ p: 3 }}>
          {selectedOrder?.updated && (
            <Typography sx={{ fontWeight: 'bold', mb: 3 }} variant="h6">
              Заказ от {format(selectedOrder?.updated, 'dd.MM.yyyy')}
              <Chip
                color={OrderStatusMapper(selectedOrder.status)?.color}
                label={OrderStatusMapper(selectedOrder.status)?.label}
                size="small"
                sx={{ ml: 2 }}
              />
            </Typography>
          )}
          {selectedOrder?.products?.map((product) => (
            <OrderItem product={product} showCount={false} />
          ))}
        </Box>
      </Drawer>
    </MainLayout>
  );
};
