import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const AuthUserFromTgApp = async ({ chatId = null, username = null }) => {
  const { data } = await axios.post(`${BACKEND_URL}/v1/auth/auth-from-tg-app`, {
    chatId,
    username,
  });
  return data;
};
