import { LoadingButton } from '@mui/lab';
import { AppBar, Fab, Stack, Toolbar } from '@mui/material';
import React from 'react';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
export const OrderFooter = ({
  disabled,
  nextButtonText,
  nextButtonHandler,
  loading = false,
  showBackButton,
  backButtonHandler,
  fullBottom = false,
}) => {
  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{ top: 'auto', bottom: fullBottom ? 0 : 60, pt: 1, pb: 2 }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack sx={{ width: '100%' }} direction="row" spacing={2}>
          {showBackButton && (
            <div>
              <Fab
                color="secondary"
                aria-label="back"
                variant="circular"
                size="small"
                onClick={backButtonHandler}
              >
                <ArrowBackIosNewOutlinedIcon />
              </Fab>
            </div>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={disabled}
            loading={loading}
            fullWidth
            onClick={nextButtonHandler}
          >
            {nextButtonText}
          </LoadingButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
