import { Fab } from '@mui/material';
import React from 'react';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useSwiper } from 'swiper/react';

export const PrevSliderButton = ({ top = '45%' }) => {
  const swiper = useSwiper();
  return (
    <Fab
      color="primary"
      sx={{
        position: 'absolute',
        left: '5px',
        zIndex: 99,
        top,
        width: '40px',
        height: '40px',
      }}
      onClick={() => swiper.slidePrev()}
    >
      <ArrowBackIosNewOutlinedIcon sx={{ width: '12px' }} />
    </Fab>
  );
};
