export const OrderStatusMapper = (statusString) => {
  if (statusString == 'created') {
    return {
      color: 'info',
      label: 'Создан',
    };
  } else if (statusString == 'paid') {
    return {
      color: 'success',
      label: 'Оплачен',
    };
  } else if (statusString == 'waitPayment') {
    return {
      color: 'warning',
      label: 'Ожидает оплаты',
    };
  } else if (statusString == 'sendToProvide') {
    return {
      color: 'warning',
      label: 'В сборке',
    };
  } else if (statusString == 'completed') {
    return {
      color: 'success',
      label: 'Завершен',
    };
  } else if (statusString == 'canceled') {
    return {
      color: 'error',
      label: 'Отменен',
    };
  } else if (statusString == 'returned') {
    return {
      color: 'error',
      label: 'Возвращен',
    };
  }
};
