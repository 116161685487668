import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetAllPopularCategories = async () => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/product-category/get-popular`,
  );
  return data;
};

export const GetOneCategoryByAlias = async ({ alias, relations }) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/product-category/get-one-by-alias/${alias}`,
    {
      params: {
        relations,
      },
    },
  );
  return data;
};

export const GetAllCategoriesToCatalog = async () => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/product-category/get-all-to-catalog`,
  );
  return data;
};
