import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { GetOneProductByAlias, SetProductViewed } from '../api/ProductApi';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Drawer,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { WishlistButton } from '../components/Wishlist/WishlistButton';
import { useMe } from '../hooks/useMe';
import { useCookies } from 'react-cookie';
import { CartButton } from '../components/Cart/CartButton';
import { url } from '../utils/url';
import { colorMapper } from '../utils/colorMapper';
import { ProductViewed } from '../components/Product/ProductViewed';
import { PrevSliderButton } from '../components/Default/Slider/PrevSliderButton';
import { NextSliderButton } from '../components/Default/Slider/NextSliderButton';
import { ProductImagePopup } from '../components/Product/ProductImagePopup';

export const ProductPage = () => {
  const { customer } = useMe();
  const [product, setProduct] = useState({});
  const [tab, setTab] = useState('1');
  const [activeVariation, setActiveVariation] = useState({});
  const [cartItem, setCartItem] = useState({});
  const [popUpCurrentSlideIndex, setPopUpCurrentSlideIndex] = useState(0);
  const [openPopUpSlider, setOpenPopUpSlider] = useState(false);

  const { productAlias, productVariation } = useParams();
  const navigate = useNavigate();
  const [cookie] = useCookies('jwt');
  const GetOneProductByAliasApi = useApi(GetOneProductByAlias);

  const SetProductViewedApi = useApi(SetProductViewed);

  const getPageDataHandler = () => {
    if (productAlias) {
      GetOneProductByAliasApi.sendRequest({
        alias: productAlias,
        jwt: cookie.jwt,
        relations: [
          'variations',
          'price',
          'pictures',
          'parameters',
          'category',
          'category.parent',
        ],
      }).then((result) => {
        setProduct(result);
        const currentVariation = result?.variations?.find(
          (v) => v.id == productVariation,
        );

        setCartItem({
          name: result?.name,
          image: result?.pictures?.length && result?.pictures[0]?.url,
          retailPrice: result?.price?.retailPrice,
          wholePrice: result?.price?.wholePrice,
          discount: result?.price?.discount,
          count:
            customer?.cart?.find(
              (c) => c.upsertUniqKey == customer?.id + '_' + result?.id,
            )?.count || 1,
          aIDs:
            result?.variations?.length > 1
              ? result?.variations
                  ?.map((v) => ({
                    variationId: v.id,
                    aID: v.aID,
                    color: v.color,
                    size: v.size,
                    stock: v.stock,
                  }))
                  .sort((a, b) => (a.stock > b.stock ? -1 : 1))
              : [
                  {
                    variationId: currentVariation.id,
                    aID: currentVariation?.aID,
                    color: currentVariation?.color,
                    size: currentVariation?.size,
                    stock: currentVariation?.stock,
                  },
                ],
          prodID: result?.prodID,
          product: {
            id: result?.id,
          },

          productURL: window?.location?.pathname,
        });
        setActiveVariation(currentVariation);
        SetProductViewedApi.sendRequest({
          jwt: cookie.jwt,
          body: { productId: result.id },
        });
      });
    }
  };
  useEffect(() => {
    getPageDataHandler();
  }, [productAlias, productVariation]);

  return (
    <MainLayout
      activeNavigate={url?.shop}
      title={`${product?.name} (${
        activeVariation.color && activeVariation.size
          ? activeVariation.color + ' - ' + activeVariation.size
          : activeVariation.color
          ? activeVariation.color
          : activeVariation?.size && activeVariation?.size
      })`}
      loading={GetOneProductByAliasApi.loading}
      titleAs="h6"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          {!GetOneProductByAliasApi?.loading ? (
            product?.pictures?.length > 1 ? (
              <Swiper
                slidesPerView={1.5}
                centeredSlides={true}
                spaceBetween={0}
                freeMode={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination, Navigation]}
                className="mySwiper"
              >
                <PrevSliderButton />
                {product?.pictures?.map((picture, index) => (
                  <SwiperSlide
                    style={{
                      overflow: 'hidden',
                      background: 'none',
                    }}
                  >
                    <Card
                      sx={{
                        mt: 2,
                        borderRadius: '20px',
                        border: 'none',
                        mx: 0.5,
                        maxWidth: '100%',
                        height: '300px',
                      }}
                      variant="outlined"
                      onClick={() => {
                        setPopUpCurrentSlideIndex(index);
                        setOpenPopUpSlider(true);
                      }}
                    >
                      <img
                        alt={`${product?.name} (${
                          activeVariation.color && activeVariation.size
                            ? activeVariation.color +
                              ' - ' +
                              activeVariation.size
                            : activeVariation.color
                            ? activeVariation.color
                            : activeVariation?.size && activeVariation?.size
                        })`}
                        src={product?.pictures[index]?.url}
                        style={{
                          width: '100%',
                          borderRadius: '20px',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Card>
                  </SwiperSlide>
                ))}
                <NextSliderButton />
              </Swiper>
            ) : (
              product?.pictures?.length && (
                <Card
                  sx={{
                    mt: 2,
                    borderRadius: '20px',
                    border: 'none',
                    mx: 0.5,
                    maxWidth: '100%',
                    height: '300px',
                  }}
                  variant="outlined"
                  onClick={() => {
                    setPopUpCurrentSlideIndex(0);
                    setOpenPopUpSlider(true);
                  }}
                >
                  <img
                    alt={`${product?.name} (${
                      activeVariation.color && activeVariation.size
                        ? activeVariation.color + ' - ' + activeVariation.size
                        : activeVariation.color
                        ? activeVariation.color
                        : activeVariation?.size && activeVariation?.size
                    })`}
                    src={product?.pictures[0]?.url}
                    style={{
                      width: '100%',
                      borderRadius: '20px',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Card>
              )
            )
          ) : (
            <Skeleton
              variant="rounded"
              sx={{ width: '100%', height: '300px', mb: 2 }}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          {!GetOneProductByAliasApi?.loading ? (
            <Card
              sx={{ mt: 1, borderRadius: '20px', border: 'none' }}
              variant="outlined"
            >
              <CardContent>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Typography>
                    Цена:{' '}
                    {product?.price?.retailPrice <
                      product?.price?.baseRetailPrice && (
                      <Chip
                        label={`-${product?.price?.discount}%`}
                        color="secondary"
                        size="small"
                      />
                    )}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h6" sx={{ mb: 0 }}>
                      {product?.price && product?.price?.retailPrice} руб.
                    </Typography>

                    {product?.price?.retailPrice <
                      product?.price?.baseRetailPrice && (
                      <Typography variant="h6" sx={{ mb: 0, opacity: 0.5 }}>
                        <s>
                          {product?.price && product?.price?.baseRetailPrice}{' '}
                          руб.
                        </s>
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          ) : (
            <Skeleton
              variant="rounded"
              sx={{ width: '100%', height: '72px', display: 'block' }}
            />
          )}
        </Grid>
        {product?.variationsMap?.length &&
          !!product?.variationsMap[0]?.length && (
            <Grid item xs={12} sx={{ position: 'relative' }}>
              {!GetOneProductByAliasApi?.loading ? (
                <Card
                  sx={{ mt: 1, borderRadius: '20px', border: 'none' }}
                  variant="outlined"
                >
                  <CardContent>
                    <Typography sx={{ mb: 2 }}>Цвета и размеры</Typography>

                    {product?.variationsMap?.map((varr) => (
                      <>
                        <Stack
                          sx={{
                            pb: 1,
                            mt: 2,
                            borderBottom: 'solid 1px #f1f1f1',
                          }}
                        >
                          {!!varr[0]?.color && (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  background: colorMapper(varr[0].color),
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                }}
                              ></Box>
                              <Typography
                                sx={{
                                  textTransform: 'capitalize',
                                }}
                              >
                                {varr[0].color}
                              </Typography>
                            </Stack>
                          )}
                          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                            {varr.map((variation) => (
                              <Card
                                sx={{
                                  width: '40px',
                                  height: '40px',
                                  mr: 1,
                                  cursor: 'pointer',
                                  border:
                                    activeVariation?.id == variation?.id
                                      ? `solid 1px #d15edc`
                                      : `solid 0.1px #000`,
                                }}
                                variant="outlined"
                                onClick={() =>
                                  navigate(
                                    `/shop/${product?.category?.slug}/${product?.slug}/${variation?.id}`,
                                  )
                                }
                              >
                                <CardContent
                                  sx={{
                                    p: 0,
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    sx={{ textAlign: 'center', lineHeight: 1 }}
                                  >
                                    {variation?.size || 'One size'}
                                  </Typography>
                                </CardContent>
                              </Card>
                            ))}
                          </Stack>
                        </Stack>
                      </>
                    ))}
                  </CardContent>
                </Card>
              ) : (
                <Skeleton
                  variant="rounded"
                  sx={{ width: '100%', height: '163px', mt: 2 }}
                />
              )}
            </Grid>
          )}

        <Grid item xs={12}>
          {!GetOneProductByAliasApi?.loading ? (
            <Card
              variant="outlined"
              sx={{
                background: '#fff',

                borderRadius: '20px',
                border: 'none',
              }}
            >
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <CartButton
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth={true}
                    cartItem={cartItem}
                    disabled={!!!+activeVariation?.stock}
                    setCartItem={(data) => setCartItem(data)}
                    updateStockHandler={() => getPageDataHandler()}
                    sx={{ width: '100%', mb: 0 }}
                  />
                  <WishlistButton
                    size="large"
                    customer={customer}
                    product={product}
                  />
                </Stack>
              </CardContent>
            </Card>
          ) : (
            <Skeleton
              variant="rounded"
              sx={{ width: '100%', height: '74px' }}
            />
          )}

          {!GetOneProductByAliasApi?.loading ? (
            <Card
              variant="outlined"
              sx={{ borderRadius: '20px', border: 'none', mt: 2 }}
            >
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={(event, newValue) => setTab(newValue)}
                    aria-label="Product content tabs"
                  >
                    <Tab label="Описание" value="1" />
                    <Tab label="Характеристики" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                  <CardContent>{product?.description}</CardContent>
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table aria-label="product parameters table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Параметр</TableCell>
                            <TableCell align="right">Значение</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!product?.parameters?.brutto && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Вес
                              </TableCell>
                              <TableCell align="right">
                                {product?.parameters?.brutto}
                              </TableCell>
                            </TableRow>
                          )}
                          {!!product?.parameters?.battaries && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Батарейки
                              </TableCell>
                              <TableCell align="right">
                                {product?.parameters?.battaries}
                              </TableCell>
                            </TableRow>
                          )}
                          {!!product?.parameters?.pack && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Упаковка
                              </TableCell>
                              <TableCell align="right">
                                {product?.parameters?.pack}
                              </TableCell>
                            </TableRow>
                          )}
                          {!!product?.parameters?.material && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Материал
                              </TableCell>
                              <TableCell align="right">
                                {product?.parameters?.material}
                              </TableCell>
                            </TableRow>
                          )}

                          {!!product?.parameters?.length && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Длинна
                              </TableCell>
                              <TableCell align="right">
                                {product?.parameters?.length}
                              </TableCell>
                            </TableRow>
                          )}
                          {!!product?.parameters?.diameter && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Диаметр
                              </TableCell>
                              <TableCell align="right">
                                {product?.parameters?.diameter}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </TabPanel>
              </TabContext>
            </Card>
          ) : (
            <Skeleton
              variant="rounded"
              sx={{ width: '100%', height: '350px', mt: 2 }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductViewed customer={customer} />
        </Grid>
      </Grid>
      <ProductImagePopup
        open={openPopUpSlider}
        close={(data) => setOpenPopUpSlider(data)}
        images={product?.pictures}
        index={popUpCurrentSlideIndex}
      />
    </MainLayout>
  );
};
