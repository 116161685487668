import { useEffect, useState } from 'react';

import { useApi } from './useApi';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { GetMeFromTelegram } from '../api/CustomerApi';
export const useMe = () => {
  const [authError, setAuthError] = useState(false);
  const [customer, setCustomer] = useState({});
  const [cookie] = useCookies('jwt');
  const [loading, setLoading] = useState(false);
  const GetMeApi = useApi(GetMeFromTelegram);
  const location = useLocation();
  useEffect(() => {
    requestMeHandler();
  }, [location]);

  const requestMeHandler = async () => {
    try {
      setLoading(true);
      const jwt = cookie.jwt;
      const result = await GetMeApi.sendRequest({ jwt });
      setCustomer(result);
      setLoading(false);
    } catch (error) {
      setAuthError(error);
    }
  };

  return {
    customer,

    authError,
    loading,
    jwt: cookie.jwt,
    requestMeHandler,
  };
};
