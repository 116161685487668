import { Card, CardContent, Radio, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import { OrderFooter } from './OrderFooter';
import { useNavigate } from 'react-router-dom';

export const SelectDeliveryScreen = ({
  order,
  UpdateOrderHandler,
  GoToOrderStep,
}) => {
  const navigate = useNavigate();
  const [deliveryType, setDeliveryType] = useState(null);
  return (
    <>
      <Stack>
        <Card
          variant="outlined"
          sx={
            deliveryType == 'selfDelivery'
              ? { border: 'solid 1px #d15edc !important', mb: 2 }
              : { mb: 2 }
          }
        >
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="start">
              <div onClick={() => setDeliveryType('selfDelivery')}>
                <Typography as="p" variant="h6">
                  Самовывоз{' '}
                </Typography>
                <Typography>
                  Заберу сам по адресу г. Москва, ул. Автозаводская, дом 16,
                  корпус 2, строение 8.
                </Typography>
              </div>
            </Stack>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={
            deliveryType == 'cdek'
              ? { border: 'solid 1px #d15edc !important', mb: 2 }
              : { mb: 2 }
          }
        >
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="start">
              <div onClick={() => setDeliveryType('cdek')}>
                <Typography as="p" variant="h6">
                  Доставка СДЭК
                </Typography>
                <Typography>
                  Хочу чтобы привезли заказ домой, в пункт выдачи заказов или в
                  постамат
                </Typography>
              </div>
            </Stack>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={
            deliveryType == 'anonimCourier'
              ? { border: 'solid 1px #d15edc !important', mb: 2 }
              : { mb: 2 }
          }
        >
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="start">
              <div onClick={() => setDeliveryType('anonimCourier')}>
                <Typography as="p" variant="h6">
                  Анонимная доставка по Москве
                </Typography>
                <Typography>
                  Доставка курьером по Москве в пределах МКАД по будням с 10:00
                  до 19:00
                </Typography>
              </div>
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <OrderFooter
        nextButtonText="Продолжить"
        loading={false}
        showBackButton={true}
        backButtonHandler={() => navigate(`/cart`)}
        disabled={!deliveryType}
        nextButtonHandler={async () => {
          let id = {};
          if (order?.delivery?.id) {
            id = {
              id: order?.delivery?.id,
            };
          }
          if (deliveryType == 'cdek') {
            navigate(`/order/${order?.id}/delivery/cdek`);
          } else if (deliveryType == 'anonimCourier') {
            navigate(`/order/${order?.id}/delivery/courier`);
          } else {
            await UpdateOrderHandler({
              ...order,
              delivery: {
                ...id,
                deliveryType: 'SELF',
                deliveryVariant: null,
                toLocationCode: null,
                fromLocationCode: null,
                toLocationString: null,
                fromLocationString: null,
                deliveryTotalSum: 0,
              },
            });
            GoToOrderStep();
          }
        }}
      />
    </>
  );
};
